import {
  COMPETITION_LIST,
  COMPETITION_LIST_TYPE,
  COMPETITION_TYPE,
  FRIEND_PREDICTION_DETAILS,
  IS_JOIN_FROM_NOTIFICATION,
  MY_PREDICTION_LIST,
  PLAN_VALIDATION,
  SPONSORED_DATA,
} from "../constant/CompetitionConstant";

let initialState = {
  selectedTab: "MyGroups",
  predictionList: [],
  competitionList: [],
  friendPredictions: {},
  isUpgrade: false,
  message: '',
  planType: '',
  isJoinFromNotification: false,
  sponsoredData: null,
  listType: 'Current',
};

const CompetitionReducer = function (state = initialState, action) {
  switch (action.type) {
    case COMPETITION_TYPE:
      return {
        ...state,
        selectedTab: action?.payload,
      };

    case MY_PREDICTION_LIST:
      return {
        ...state,
        predictionList: action?.payload,
      };

    case COMPETITION_LIST:
      return {
        ...state,
        competitionList: action?.payload,
      };

    case FRIEND_PREDICTION_DETAILS:
      return {
        ...state,
        friendPredictions: action?.payload,
      };

    case PLAN_VALIDATION:
      return {
        ...state,
        isUpgrade: action?.payload?.isUpgrade,
        message: action?.payload?.message,
        planType: action?.payload?.planType,

      };

    case IS_JOIN_FROM_NOTIFICATION:
      return {
        ...state,
        isJoinFromNotification: action?.payload
      };

    case SPONSORED_DATA:
      return {
        ...state,
        sponsoredData: action?.payload
      };

    case COMPETITION_LIST_TYPE:
      return {
        ...state,
        listType: action?.payload
      };


    default:
      return state;
  }
};

export default CompetitionReducer;
