import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "./assets/css/responisve.css";
import './App.css'
import "@fortawesome/fontawesome-free/css/all.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CombineRoutes from "./routes/CombineRoutes";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Session from "./utils/session";
import io from "socket.io-client";
import { ONLINE_USER_LIST } from "./redux/constant/Auth";
const socket = io.connect(process.env.REACT_APP_SOCKET_NODE_URL, { transport: "websocket" });



function App() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state?.auth);
  const Socket = useSelector((state) => state?.prediction?.Socket);
  const user = Session.getSession('token')


  useEffect(() => {
    if (auth) {
      console.log('session_id', user);
      dispatch({ type: 'Socket', payload: user?.UserID })
    } else if (Socket) {
      Socket.disconnect()
    }
  }, [auth])

  // socket.on('onlineUserList', (data) => {
  //   console.log('user list===================================================================',data )
  // })

  socket.on(`online_list_${user?.UserID}`, (data) => {
    dispatch({
      type: ONLINE_USER_LIST,
      payload: data
    })

  })



  // useEffect(() => {

  //   Socket.on("online_userList", (data) => {
  //     console.log('user list', data)
  //   })
  // })





  return (
    <div className="App">
      <CombineRoutes />
      <ToastContainer />
    </div>

  );
}

export default App;
