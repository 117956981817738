import { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import Session from '../../utils/session';
import { getCommentsList, setLoading, getPostList } from '../../redux/actions/DashboardActions'
import Socket from "../../Socket";





const NastedCommentList = ({ post, comment, indx, handelLike_comment, handelShowBox }) => {
    const user = Session.getSession("token");
    const ProfilePic = Session.getSession("ProfilePic");
    const dispatch = useDispatch();
    const [commentList, setCommentList] = useState([])
    const [apiHandel, setApiHandel] = useState(false)




    const getNastedComments = async (postId, commentId) => {
        // if (apiHandel) {
        //     setApiHandel(!apiHandel)
        //     return
        // }
        // setApiHandel(!apiHandel)
        const apiData = { post_id: postId, comment_id: commentId }
        const result = await dispatch(getCommentsList(apiData));
        setCommentList(result?.Data)
        console.log('result api==>>>>', result)
    }



    const AddCommentBox = ({ commentId }) => {
        const [valueInput, setValueInput] = useState('');


        const addComment = (commentId) => (e) => {
            if (e.key == "Enter") {
                dispatch(setLoading(true));
                const data = {
                    user_id: user?.UserID,
                    post_id: post?.id,
                    comments: e.target.value,
                    parent_comment_id: commentId
                }
                getNastedComments(post?.id, commentId)
                Socket.emit("sub_commented", data);
                Socket.emit(`notification_send`, { UserGUID: user?.UserID });
                setValueInput('')
            }
        };
        const sendMessageByBtn = (commentId) => (e) => {
            dispatch(setLoading(true));
            const data = {
                user_id: user?.UserID,
                post_id: post?.id,
                comments: valueInput,
                parent_comment_id: commentId
            }
            getNastedComments(post?.id, commentId)
            Socket.emit("sub_commented", data);
            Socket.emit(`notification_send`, { UserGUID: user?.UserID });
        }



        return (
            <div className="post-editor d-flex align-items-center">
                <figure className="small-circle">
                    <img src={process.env.REACT_APP_IMAGE_URL + ProfilePic} alt="" />
                </figure>


                <div className="send-message-container" style={{ marginLeft: 10, color: 'white' }}>
                    <input
                        type="text"
                        className="form-control w-100 ms-0 transparent px-3 custom-post-comment-input"
                        placeholder="Write a comment..."
                        onKeyDown={addComment(commentId)}
                        onChange={(e) => setValueInput(e.target.value)}
                        value={valueInput}
                    />


                    <div className="send-message-btn" onClick={sendMessageByBtn(commentId)} style={{ marginLeft: 5, }} >
                        <i className="fa fa-paper-plane" aria-hidden="true" ></i>
                    </div>
                </div>



            </div>
        )
    }


    // ***********socket useEffect****************


    Socket.off("postCommentLike").on("postCommentLike", (data) => {
        dispatch(getPostList());
    });


    Socket.off("postComment").on("postComment", (data) => {
        dispatch(getPostList());
        console.log('post commented commented==>>>>>', data)
    });


    Socket.off("sub_commented_post").on("sub_commented_post", (data) => {
        const apiData = { post_id: data?.comment?.post_id, comment_id: data?.comment?.parent_comment_id }
        dispatch(getPostList());
        //   dispatch(getCommentsList(apiData));
        // dispatch(setLoading(false));

    });



    return (


        <Accordion className='comments-accordian' defaultActiveKey="0" >

            <Accordion.Item className="position-relative" >
                <div className="like-comment">
                    <ul className="list-unstyled">
                        <li onClick={handelLike_comment(comment)}>
                            <span  >{comment?.isLiked == 1 ? 'Liked' : "Like"}</span>
                            <span className="like_count" > ({comment?.likeCount})</span>
                        </li>
                        <li onClick={() => handelShowBox(indx)}>
                            <span  >Comment</span>
                            <span className="like_count" > ({comment?.commentCount})</span>
                        </li>
                    </ul>
                </div>

                <Accordion.Header onClick={() => getNastedComments(post?.id, comment?.id)} > <img src='./assets/mobileimages/angleDown_green.svg' alt="" /> </Accordion.Header>


                <Accordion.Body className="px-0">
                    <>
                        {
                            commentList && commentList.length > 0 ? commentList?.map((items, indexs) =>
                            (
                                <div key={indexs}>
                                    <li>
                                        <ul className="mt-3 ps-0">
                                            <li>
                                                <div className="d-flex">
                                                    <figure className="middle-circle">
                                                        <img
                                                            src={process.env.REACT_APP_IMAGE_URL + items?.user_image}
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <figcaption>
                                                        <div className="figcaption">
                                                            <h4>{items?.user_name}</h4>
                                                            <p style={{ color: '#afafaf', fontSize: 10 }}> {moment(items?.created_at).format('DD/MM/yy, hh:mm A')} </p>
                                                            <p className="mb-0">
                                                                {items?.comment}
                                                            </p>
                                                        </div>

                                                        <div className="like-comment-post">
                                                            {/* <span>Like</span> */}
                                                        </div>
                                                    </figcaption>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </div>

                            )
                            ) : (<div>
                                <span style={{ color: 'gray' }}>no comments</span>
                            </div>)
                        }
                        <AddCommentBox commentId={comment?.id} />
                    </>

                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


    )
}

export default NastedCommentList