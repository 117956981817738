import apiPath from "../../utils/apiPath";
import helper from "../../utils/helper";
import axios from "axios";
import _ from "lodash";
import Session from "../../utils/session";
import { toast, ToastContainer } from "react-toastify";

import {
  GET_LEAGUES_LIST,
  GET_WEEKS_LIST,
  GET_MATCH_LIST,
  LOADER,
  SET_LEAGUE_ID,
  GET_TEAM_DETAILS,
  SHOW_MODAL,
  NOTIFICATION_LIST,
  NOTIFICATION_COUNT,
  HEAD_2_HEAD_DETAILS,
  COMPETITION_GUID,
  GET_POST_LIST,
  GET_COMMENT_LIST,
  SHOW_ADD_PREDICTION_MODAL,
  SHOW_JOIN_MODAL,
  WEEK_GUID,
  GET_MATCH_COMMENT_LIST,
  OPEN_MATCH_CHAT,
  VALIDATION_LIST,
  LEAGUE_ID,
  WEEK_INDEX,
  PRIZE_DETAILS,
} from "../constant/Dashboard";
import { COUNTRY_LIST } from "../constant/Profile";
import { useSelector } from "react-redux";
import { IS_JOIN_FROM_NOTIFICATION } from "../constant/CompetitionConstant";

export const getList = (data) => async (dispatch) => {
  const user = Session.getSession("token");
  try {
    let formData = new FormData();
    formData.append("SessionKey", user?.SessionKey);
    formData.append("Params", "LeagueFlag,Status,LeagueID");
    formData.append("CompetitionGUID", "622039ee-5dba-02b4-98f2-e2d99e730317");
    formData.append("Filter", "CurrentSeasonLeagues");

    const res = await helper.api(apiPath.getLeagues, "post", formData);

    if (res?.ResponseCode === "200") {

      var element = res?.Data?.Records[2];
      res?.Data?.Records.splice(2, 1);
      res?.Data?.Records.splice(0, 0, element);


      dispatch({
        type: GET_LEAGUES_LIST,
        payload: res,
      });
      dispatch({
        type: SET_LEAGUE_ID,
        payload: res?.Data?.Records[0]?.LeagueGUID,
      });
      dispatch({
        type: LEAGUE_ID,
        payload: res?.Data?.Records[0]?.LeagueID,
      });

      let id = res?.Data?.Records[0]?.LeagueID

      // try {
      //   let formData = new FormData()
      //   formData.append('SessionKey', user?.SessionKey)
      //   formData.append('LeagueID', id)
      //   let res = await helper.api(apiPath.getPrizes, "post", formData);
      //   if (res?.ResponseCode === "200") {
      //     dispatch({
      //       type: PRIZE_DETAILS,
      //       payload: res?.Data?.Records?.Prizes,
      //     });
      //   }
      // } catch (error) {
      //   console.log("eror in getPrizes api-->>>", error);
      // }
      return res;
    }
  } catch (error) {
    console.log("error in get leauge list api::", error);

  }
};

export const getPrizes = (data) => async (dispatch, getState) => {
  const user = Session.getSession("token");
  const LeagueID = getState()?.dashboard?.LeagueID;
  console.log(data);
  try {
    let formData = new FormData()
    formData.append('SessionKey', user?.SessionKey)
    formData.append('LeagueID', data !== undefined ? data : LeagueID)
    let res = await helper.api(apiPath.getPrizes, "post", formData);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: PRIZE_DETAILS,
        payload: res?.Data?.Records?.Prizes,
      });
    }
  } catch (error) {
    console.log("eror in getPrizes api-->>>", error);
  }
}



export const getWeeks = (data) => async (dispatch, getState) => {
  const user = Session.getSession("token");
  const league_id = getState()?.dashboard?.selectedLeague;
  try {
    let formData = new FormData();
    formData.append("SessionKey", user?.SessionKey);
    formData.append(
      "Params",
      "LeagueFlag, WeekStartDate, WeekEndDate, Status, WeekCount"
    );
    formData.append("OrderBy", "WeekCount");
    formData.append("Sequence", "ASC");
    formData.append("UpcomingWeekStatus", "Pending");
    formData.append("LeagueGUID", league_id);

    const res = await helper.api(apiPath.getWeeks, "post", formData);


    if (res?.ResponseCode === "200") {
      const response = await { res };

      console.log('response=========>>>>', response)
      console.log('response=========>>>>', response?.res?.Data?.is_stage)
      if (response?.res?.Data?.is_stage) {
        let dict = response?.res?.Data?.Records.concat(response?.res?.Data?.stageData)
        var data = { Records: dict }
        dispatch({
          type: GET_WEEKS_LIST,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_WEEKS_LIST,
          payload: response?.res?.Data,
        });
      }





      dispatch({
        type: WEEK_INDEX,
        payload: response?.res?.stage_index > -1 && response?.res?.week_index <= -1 ? Number(response?.res?.Data?.TotalRecords) + Number(response?.res?.stage_index) : Number(response?.res?.week_index),
      });



      // console.log(response?.res?.week_index, 'weekIndex');
      dispatch({
        type: WEEK_GUID,
        payload: response?.Data?.Records[0]?.WeekGUID,
      });


      dispatch({
        type: LOADER,
        payload: false,
      });


    }
  } catch (error) {
    console.log("error in get Weeks list api::", error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};
export const getMatches = (WeekGUID) => async (dispatch, getState) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const league_id = getState()?.dashboard?.selectedLeague;
  const user = Session.getSession("token");
  try {
    let formData = new FormData();
    formData.append(
      "Params",
      "IsPredicted,PredictionDetails,MatchStartDateTime,MatchScoreDetails,TeamGUIDLocal,TeamGUIDVisitor,LeagueName,LeagueFlag,MatchDate,MatchTime,MatchTimeIn12HrsFormat,TeamNameLocal,TeamNameVisitor,TeamNameShortLocal,TeamNameShortVisitor,TeamFlagLocal,TeamFlagVisitor,Status,TeamIDLocal,TeamIDVisitor,MatchID,TeamIDLocalLive,TeamIDVisitorLive,VenueName,LeagueID,IsJoined,timestamp,SeasonIDLive"
    );
    formData.append("OrderBy", "MatchStartDateTime");
    formData.append("Sequence", "ASC");
    formData.append("Status", "Pending");
    formData.append("Filters[1]", "DateWiseMatches");
    formData.append("GetMatchesGreaterThanToday", "yes");
    formData.append("SessionKey", user?.SessionKey);
    formData.append("LeagueGUID", league_id);
    formData.append("WeekGUID", WeekGUID);
    formData.append("StageType", "Group Stage");

    const res = await helper.api(apiPath.getMatches, "post", formData);

    if (res?.ResponseCode === "200") {
      if (!_.isEmpty(res?.Data)) {
        dispatch({
          type: GET_MATCH_LIST,
          payload: res,
        });
      }
      dispatch({
        type: LOADER,
        payload: false,
      });
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      // toast.error(res?.Message)
    }
  } catch (error) {
    console.log("error in get Weeks list api::", error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};
export const setLoading = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOADER,
      payload: data,
    });
  } catch (error) {
    console.log("error in set loader list api::", error);
  }
};
export const setSelectedLeague = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LEAGUE_ID,
      payload: data?.LeagueGUID,
    });
    dispatch({
      type: LEAGUE_ID,
      payload: data?.LeagueID,
    });
  } catch (error) {
    console.log("error in set loader list api::", error);
  }
};
export const getTeamDetails = (data, session_id) => async (dispatch) => {
  console.log(' get team details api called');
  const user = Session?.getSession('token')
  dispatch({
    type: LOADER,
    payload: true,
  });
  try {
    let payloadData = {
      SessionKey: user?.SessionKey,
      session_id: session_id,
      team_id: data,
    };
    const res = await helper.api(apiPath.getTeamDetails, "post", payloadData);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: GET_TEAM_DETAILS,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: SHOW_MODAL,
        payload: true,
      });
    }
  } catch (error) {
    console.log("error in get getTeamDetails api::", error);
  }
};
export const getNotificationList = (data, getState) => async (dispatch) => {
  const user = Session.getSession("token");
  try {
    let payloadData = {
      SessionKey: user?.SessionKey,
    };

    const res = await helper.api(
      apiPath.getNotificationList,
      "post",
      payloadData
    );
    if (res?.ResponseCode === "200") {
      dispatch({
        type: NOTIFICATION_LIST,
        payload: res?.Data,
      });
    }
  } catch (error) {
    console.log("error in get getNotificationList api::", error);
  }
};
export const getNotificationCount = (data, getState) => async (dispatch) => {
  const user = Session.getSession("token");
  try {
    let payloadData = {
      SessionKey: user?.SessionKey,
    };
    const res = await helper.api(
      apiPath.getNotificationCount,
      "post",
      payloadData
    );
    if (res?.ResponseCode === "200") {
      dispatch({
        type: NOTIFICATION_COUNT,
        payload: res,
      });
    }
  } catch (error) {
    console.log("error in get getNotificationCount api::", error);
  }
};
export const getHeade2HeadeDetails =
  (data, LeagueID, type) => async (dispatch, getState) => {
    const WeekGUID = getState()?.dashboard?.weekGUID
    const user = Session.getSession("token");
    dispatch({
      type: LOADER,
      payload: true,
    });
    try {
      let payloadData = {
        SessionKey: user?.SessionKey,
        local_team_id: data?.TeamIDLocalLive,
        visitor_team_id: data?.TeamIDVisitorLive,
        LeagueID: LeagueID,
      };
      const res = await helper.api(apiPath.getHead2Head, "post", payloadData);
      if (res?.ResponseCode === "200") {
        dispatch({
          type: LOADER,
          payload: false,
        });
        dispatch({
          type: HEAD_2_HEAD_DETAILS,
          payload: res,
        });
        dispatch({
          type: OPEN_MATCH_CHAT,
          payload: true,
        });
        if (type === "joinCompetition") {
          dispatch({
            type: SHOW_JOIN_MODAL,
            payload: true,
          });
        } else if (type === "edit" || type === "add") {
          dispatch({
            type: SHOW_ADD_PREDICTION_MODAL,
            payload: true,
          });
        }
      } else if (res?.ResponseCode === "500" && res?.Message === 'Record not found!') {
        dispatch({
          type: LOADER,
          payload: false,
        });
        toast.error('Teams details not found!');

      } else {
        toast.error(res.Message);
        dispatch({
          type: OPEN_MATCH_CHAT,
          payload: true,
        });
        dispatch({
          type: LOADER,
          payload: false,
        });
      }
    } catch (error) {
      console.log("error in get getHeade2HeadeDetails api::", error);
    }
  };
export const getCompetitionGUID = (data, getState) => async (dispatch) => {
  const user = Session.getSession("token");
  try {
    let formData = new FormData();
    formData.append("SessionKey", user?.SessionKey);
    let res = await helper.api(apiPath.getCompetition, "post", formData);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: COMPETITION_GUID,
        payload: res?.Data?.Records[0],
      });
    } else {
      toast.error(res.Message);
    }
  } catch (error) {
    console.log("error in get getCompetitionGUID api::", error);
  }
};
export const createPostAction = (data) => async (dispatch) => {
  //   const session_id = Session.getSession("token");
  try {
    const user = Session.getSession("token");

    let formData = new FormData();
    formData.append("session_key", user?.SessionKey);
    formData.append("description", data?.description !== undefined ? data?.description : '');
    formData.append("image", data?.image);

    const res = await helper.api(apiPath.createPost, "post", formData);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.Message);
      return true;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.Message);
      return false;
    }
  } catch (error) {
    console.log("error in get create post api::", error);
  }
};

export const updatePost = (data) => async (dispatch) => {
  //   const session_id = Session.getSession("token");
  try {
    const user = Session.getSession("token");

    let formData = new FormData();
    formData.append("session_key", user?.SessionKey);
    formData.append("description", data?.description !== undefined ? data?.description : '');
    formData.append("image", data?.image !== undefined ? data?.image : "");
    formData.append("id", data?.id);



    const res = await helper.api(apiPath.updatePost, "post", formData);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.Message);
      return true;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.Message);
      return false;
    }
  } catch (error) {
    console.log("error in get create post api::", error);
  }
};
export const deletePost = (data) => async (dispatch) => {
  //   const session_id = Session.getSession("token");
  try {
    const user = Session.getSession("token");

    let payload = {
      "session_key": user?.SessionKey,
      "id": data?.id
    }

    const res = await helper.api(apiPath.deletePost, "post", payload);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.success(res?.Message);
      return true;
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast.error(res?.Message);
      return false;
    }
  } catch (error) {
    console.log("error in get create post api::", error);
  }
};
export const getPostList = (page) => async (dispatch) => {
  const user = Session.getSession("token");
  try {
    let payload = {
      session_key: user?.SessionKey,
      pageno: page !== undefined ? 1 : page
    };
    const res = await helper.api(apiPath.getPosts, "post", payload);

    if (res?.ResponseCode === "200") {
      dispatch({
        type: GET_POST_LIST,
        payload: res,
      });
    }
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log("error in get post list api::", error);
    dispatch({
      type: LOADER,
      payload: false,
    });
  }
};
export const getCommentsList = (data) => async (dispatch) => {
  const user = Session.getSession("token");
  try {
    let payload = {
      session_key: user?.SessionKey,
      post_id: data?.post_id,
      comment_id: data?.comment_id,
    };
    const res = await helper.api(apiPath.getComments, "post", payload);

    if (res?.ResponseCode === "200") {
      dispatch({
        type: GET_COMMENT_LIST,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res
    } else {
      dispatch({
        type: GET_COMMENT_LIST,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      return false
    }
  } catch (error) {
    console.log("error in get comment list api::", error);
  }
};
export const joinCompetition = (data) => async (dispatch, getState) => {
  const WeekGUID = getState()?.dashboard?.weekGUID
  dispatch({
    type: LOADER,
    payload: true,
  });
  console.log(data);
  const user = Session.getSession("token");
  try {
    let payload = {
      SessionKey: user?.SessionKey,
      LeagueID: data?.LeagueID,
    };
    const res = await helper.api(apiPath.joinCompetition, "post", payload);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: SHOW_JOIN_MODAL,
        payload: false,
      });
      getMatches(WeekGUID);
      toast?.success(res?.Message);
      dispatch({
        type: IS_JOIN_FROM_NOTIFICATION,
        payload: false
      })
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
      toast?.error(res?.Message);
    }
  } catch (error) {
    console.log("error in get comment list api::", error);
  }
};
export const addPrediction = (data) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const user = Session.getSession("token");
  try {
    const res = await helper.api(apiPath.addPrediction, "post", data);
    console.log(res);

    if (res?.ResponseCode === "200") {
      toast?.success("Prediction Added Successfully");
      dispatch({
        type: SHOW_ADD_PREDICTION_MODAL,
        payload: false,
      });
      dispatch(getMatches(data?.WeekGUID));
    } else {
      toast.error(res?.Message);
    }
    dispatch({
      type: LOADER,
      payload: false,
    });
  } catch (error) {
    console.log("error in get addPrediction  api::", error);
  }
};
export const getMatchComments = (data) => async (dispatch) => {
  const user = Session.getSession("token");
  try {
    let payload = {
      session_key: user?.SessionKey,
      match_id: data?.MatchID,
    };

    const res = await helper.api(apiPath.getMatch_comments_likes, "post", data);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: GET_MATCH_COMMENT_LIST,
        payload: res,
      });
      // dispatch({
      //   type: LOADER,
      //   payload: false,
      // });
    } else {
      dispatch({
        type: GET_MATCH_COMMENT_LIST,
        payload: res,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.log("error in get comment list api::", error);
  }
};

export const getValidationDetails = (data) => async (dispatch, getState) => {
  const user = Session.getSession("token");
  try {
    let payload = {
      SessionKey: user?.SessionKey,
      GroupID: data?.GroupID,
    };

    const res = await helper.api(apiPath.getValidationDetails, "post", payload);
    if (res?.ResponseCode === "200") {
      dispatch({
        type: VALIDATION_LIST,
        payload: res?.Data,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    } else {
      dispatch({
        type: LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.log("error in get comment list api::", error);
  }
};
