import { Outlet, Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {  useSelector } from 'react-redux'
import Session from "../utils/session";


const StaticLayout = () => {
    const navigate = useNavigate()
    const [sideBar, setSideBar] = useState(false)
    const user = Session.getSession("token");

    const handleNavigate = (data) => {
        navigate(data)
        setSideBar(!sideBar)
    }


    return (
        <>

            <div className="main-wrapper-outer">
                <header className="site_header fixed-top ng-scope">
                    <nav className="home-header navbar navbar-expand-lg my-0 my-md-3">
                        <div className="container">
                            <Link to={user ? '/' : '/home'}> <span className="navbar-brand order-1 order-xl-1" ><img src="./assets/images/logo.svg" className="img-fluid" /></span> </Link>
                            <div className="right_menu d-flex align-items-center order-2 order-xl-3">
                                <ul className="header-right d-flex align-items-center">
                                    <li className="mr-1 ng-scope" ng-if="!isLoggedIn">
                                        {
                                            !user && <Link to="/login" className="nav-link" >Sign in/Sign up</Link>
                                        }

                                    </li>
                                    <li className="toggle-btn ng-scope" ng-if="!isLoggedIn">
                                        <a className="nav-link" onClick={() => setSideBar(!sideBar)}>
                                            <img src="./assets/images/front/menu.svg" className="img-fluid" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <div className={`${sideBar ? "mm front_menu_sidebar" : "front_menu_sidebar"}`} style={{ transform: sideBar && "translate(0%)" }} >
                        <div className="menu_head">
                            <h2>Menu</h2>
                            <button onClick={() => setSideBar(!sideBar)}><img src="./assets/images/front/arrow.svg" /></button>
                        </div>


                        <div className="front_menu_link">
                            <ul>

                                {
                                    !user && <li onClick={() => handleNavigate('/login')}> <a >Sign in/Sign up</a></li>
                                }
                                <li onClick={() => handleNavigate('/scoring')}> <a >Scoring</a></li>
                                <li onClick={() => handleNavigate('/game-rules')}> <a >Game Rules</a></li>

                                <li onClick={() => handleNavigate('/privacy-policy')}> <a >Privacy Policy</a></li>
                                <li onClick={() => handleNavigate('/terms-conditions')}><a >PicknPredict T&C</a></li>
                                <li onClick={() => handleNavigate('/about-us')}> <a >About us</a></li>
                            </ul>
                        </div>

                    </div>

                </header>



                <Outlet />

            </div>
        </>
    );
};

export default StaticLayout;
