import { IS_EDIT, SELECTED_MATCH } from "../constant/CompetitionConstant";
import {
  GET_LEAGUES_LIST,
  GET_WEEKS_LIST,
  GET_MATCH_LIST,
  LOADER,
  SET_LEAGUE_ID,
  GET_TEAM_DETAILS,
  SHOW_MODAL,
  NOTIFICATION_LIST,
  NOTIFICATION_COUNT,
  SHOW_JOIN_MODAL,
  HEAD_2_HEAD_DETAILS,
  COMPETITION_GUID,
  GET_POST_LIST,
  GET_COMMENT_LIST,
  GET_COMMENT_LIST_DISPATCH,
  SHOW_ADD_PREDICTION_MODAL,
  TEAM_FLAG,
  WEEK_GUID,
  GET_MATCH_COMMENT_LIST,
  OPEN_MATCH_CHAT,
  VALIDATION_LIST,
  IS_CHAT_OPEN,
  IS_CHAT_SCREEN,
  ONLINE_USER,
  LEAGUE_ID,
  WEEK_INDEX,
  MATCH_STATUS_UPDATE,
  PRIZE_DETAILS,
  PAGE_RELOAD,
  OPEN_LEAGUE_MODAL,
} from "../constant/Dashboard";

let initialState = {
  dashboardList: {},
  weeksList: {},
  matchList: {},
  selectedLeague: {},
  LeagueID: null,
  teamDetails: {},
  loader: false,
  isShow: false,
  notificationList: [],
  notificationCount: 0,
  isOpen: false,
  teamFlag: null,
  weekGUID: null,
  competetionDetails: {},
  competitionGUID: {},
  sideFeeds: {},
  commentList: {},
  showAddPredModal: false,
  matchCommentList: {},
  showMatchChat: false,
  head2head: {},
  selectedMatch: {},
  isEdit: false,
  validationDetails: {},
  isChatOpen: false,
  online_user: {},
  weekIndex: 0,
  stageIndex: 0,
  prizeDetails: null,
  pageReload: true,

  // Mobile Leagu State
  openLeagueModal: false,


};

const DashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LEAGUES_LIST:
      return {
        ...state,
        dashboardList: action?.payload?.Data,
      };

    case GET_WEEKS_LIST:
      return {
        ...state,
        weeksList: action?.payload || {},
      };

    case WEEK_INDEX:
      return {
        ...state,
        weekIndex: action?.payload,
      };
    case GET_MATCH_LIST:
      return {
        ...state,
        matchList: action?.payload?.Data || {},
      };

    case LOADER:
      return {
        ...state,
        loader: action?.payload,
      };

    case SET_LEAGUE_ID:
      return {
        ...state,
        selectedLeague: action?.payload,
      };

    case LEAGUE_ID:
      return {
        ...state,
        LeagueID: action?.payload,
      };

    case GET_TEAM_DETAILS:
      return {
        ...state,
        teamDetails: action?.payload,
      };

    case SHOW_MODAL:
      return {
        ...state,
        isShow: action?.payload,
      };

    case NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action?.payload,
      };

    case NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action?.payload?.Data?.TotalUnread,
      };

    case SHOW_JOIN_MODAL:
      return {
        ...state,
        isOpen: action?.payload,
        competetionDetails: action?.payload,
      };

    case HEAD_2_HEAD_DETAILS:
      return {
        ...state,
        head2head: action?.payload,
      };

    case COMPETITION_GUID:
      return {
        ...state,
        competitionGUID: action?.payload,
      };

    case GET_POST_LIST:
      return {
        ...state,
        sideFeeds: action?.payload?.Data,
      };

    case GET_COMMENT_LIST:
      return {
        ...state,
        commentList: action?.payload?.Data,
      };

    case GET_COMMENT_LIST_DISPATCH:
      return {
        ...state,
        commentList: [...state.commentList, action?.payload],
      };

    case SHOW_ADD_PREDICTION_MODAL:
      return {
        ...state,
        showAddPredModal: action?.payload,
      };

    case TEAM_FLAG:
      return {
        ...state,
        teamFlag: action?.payload,
      };

    case WEEK_GUID:
      return {
        ...state,
        weekGUID: action?.payload,
      };

    case GET_MATCH_COMMENT_LIST:
      return {
        ...state,
        matchCommentList: action?.payload?.Data,
      };

    case OPEN_MATCH_CHAT:
      return {
        ...state,
        showMatchChat: action?.payload,
      };

    case SELECTED_MATCH:
      return {
        ...state,
        selectedMatch: action?.payload,
      };

    case IS_EDIT:
      return {
        ...state,
        isEdit: action?.payload,
      };

    case VALIDATION_LIST:
      return {
        ...state,
        validationDetails: action?.payload,
      };
    //for chat screen
    case IS_CHAT_OPEN:
      return {
        ...state,
        isChatOpen: action?.payload,
      };

    case IS_CHAT_SCREEN:
      if (!state.isChatOpen) {
        state.online_user = null
      }

      return {
        ...state,
        isChatScreen: action?.payload,
      };

    case ONLINE_USER:
      return {
        ...state,
        online_user: action?.payload,
      };

    case PRIZE_DETAILS:
      return {
        ...state,
        prizeDetails: action?.payload,
      };

    case PAGE_RELOAD:
      return {
        ...state,
        pageReload: action?.payload,
      };



    case MATCH_STATUS_UPDATE:
      { console.log(action?.payload, '=>>>>>>>>>>> match status and score socket called'); }
      Object.keys(state?.matchList?.Records).map((propKey) => {
        state?.matchList?.Records[propKey].Matches?.map((childPropKey, i) => {

          console.log("LocalTeamScore", action?.payload?.match?.VisitorTeamScore)
          console.log("VisitorTeamScore", action?.payload?.match?.VisitorTeamScore)
          console.log("MatchStatus", action?.payload?.match?.MatchStatus)

          if (action?.payload?.match?.MatchID == childPropKey?.MatchID) {


            console.log("LocalTeamScore", action?.payload?.match?.VisitorTeamScore)
            console.log("VisitorTeamScore", action?.payload?.match?.VisitorTeamScore)
            console.log("MatchStatus", action?.payload?.match?.MatchStatus)

            if (action?.payload?.match?.LocalTeamScore !== undefined) {
              console.log("Status Called LocalTeamScore", action?.payload?.match?.VisitorTeamScore)
              console.log("Status Called VisitorTeamScore", action?.payload?.match?.VisitorTeamScore)
              console.log("Status Called MatchStatus", action?.payload?.match?.MatchStatus)

              childPropKey.Status = childPropKey.Status
              childPropKey.MatchScoreDetails.LocalTeamScore = action?.payload?.match?.LocalTeamScore
              childPropKey.MatchScoreDetails.VisitorTeamScore = action?.payload?.match?.VisitorTeamScore
            }
            if (action?.payload?.match?.MatchStatus !== undefined) {
              console.log("Score Called LocalTeamScore", action?.payload?.match?.VisitorTeamScore)
              console.log("Score Called VisitorTeamScore", action?.payload?.match?.VisitorTeamScore)
              console.log("Score Called MatchStatus", action?.payload?.match?.MatchStatus)

              childPropKey.MatchScoreDetails.LocalTeamScore = 0
              childPropKey.MatchScoreDetails.VisitorTeamScore = 0


              childPropKey.Status = action?.payload?.match?.MatchStatus
            }

          }


        })
      })

      return {
        ...state,
      };


    // Mobile Website Reducers

    case OPEN_LEAGUE_MODAL:
      return {
        ...state,
        openLeagueModal: action?.payload,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
