import { io } from "socket.io-client";
import {
  GET_PREDICTION_GLOBAL_RANKING,
  GET_PREDICTION_LEADERBOARD,
  GET_PREDICTION_LIST,
} from "../constant/PredictionConstant.";

let initialState = {
  predictionList: [],
  globalPredictionList: [],
  predictionLeaderboard: [],
};

const PredictionReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PREDICTION_LIST:
      return {
        ...state,
        predictionList: action?.payload?.Data,
      };
    case GET_PREDICTION_GLOBAL_RANKING:
      return {
        ...state,
        globalPredictionList: action?.payload?.data,
      };
    case GET_PREDICTION_LEADERBOARD:
      return {
        ...state,
        predictionLeaderboard: action?.payload?.Data,
      };

    case 'Socket':
      console.log('data in reducersssss sockett==>>>>>>', action.payload)
      return {
        ...state,
        Socket: io.connect(process.env.REACT_APP_SOCKET_NODE_URL, { query: { user_id: action.payload } }),
      };


    default:
      return state;
  }
};

export default PredictionReducer;
