export const COMPETITION_TYPE = 'COMPETITION_TYPE'
export const MY_PREDICTION_LIST = 'MY_PREDICTION_LIST'
export const COMPETITION_LIST = 'COMPETITION_LIST'
export const SELECTED_MATCH = 'SELECTED_MATCH'
export const IS_EDIT = 'IS_EDIT'
export const FRIEND_PREDICTION_DETAILS = 'FRIEND_PREDICTION_DETAILS'
export const PLAN_VALIDATION = 'PLAN_VALIDATION'
export const IS_JOIN_FROM_NOTIFICATION = 'IS_JOIN_FROM_NOTIFICATION'
export const SPONSORED_DATA = 'SPONSORED_DATA'
export const COMPETITION_LIST_TYPE = 'COMPETITION_LIST_TYPE'







