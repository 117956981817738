import { LOGIN_SUCCESS, LOGOUT_SUCCESS, FORGOT_PASSWORD, VERIFY_OTP, PACKAGE_ID, ONLINE_USER_LIST } from '../constant/Auth'
import Session from "../../utils/session";
import { forgotPassword } from '../actions/AuthActions';
import { ONLINE_USER } from '../constant/Dashboard';

var getSession = Session.getSession("token")

let initialState = {
    user: {} ,
    session_id: getSession || null,
    auth: getSession ? true : false,
    recovery:{},
    changePassword_model:false,
    onlineUserList:[]
};


const LoginReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            Session.setSession("token",action?.payload?.Data)
            Session.setSession("package",action?.payload?.Data?.SubscriptionID)
            Session.setSession("ProfilePic",action?.payload?.Data?.ProfilePic)

            
            return {
                ...state,
                auth: action?.payload?.ResponseCode === '200',
                session_id: action?.payload?.Data?.SessionKey,
                user: action?.payload?.Data,
            };
        case LOGOUT_SUCCESS:
            Session.clearSession("token")
            Session.clearSession("package")
            Session.clearSession("ProfilePic")
            return {
                ...state,
                auth: false,
                session_id:null,
                user: null,
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
               recovery:{email:action?.payload, type:'forgot_password'}
                
            };
        case VERIFY_OTP:
            return {
                ...state,
               recovery:{}
                
            };

            case ONLINE_USER_LIST:
                return {
                    ...state,
                   onlineUserList:action.payload
                    
                };
            
        default:
            return state;
    }
};

export default LoginReducer;
    
  