import { lazy } from "react";

// project imports
import Loadable from "../components/loader/Loadable";
import AuthLayout from "../layout/AuthLayout";

const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Competition = Loadable(
  lazy(() => import("../pages/Competitions/Competition"))
);
const Standings = Loadable(lazy(() => import("../pages/Standings/Standings")));
const PlayerState = Loadable(
  lazy(() => import("../pages/playerState/PlayerState"))
);
const MyPrediction = Loadable(
  lazy(() => import("../pages/prediction/MyPrediction"))
);
const MyProfile = Loadable(lazy(() => import("../pages/myProfile/MyProfile")));
const Subscription = Loadable(
  lazy(() => import("../pages/Subscription/Subscription"))
);
const Popup = Loadable(lazy(() => import("../pages/Popup")));
const FriendPrediction = Loadable(lazy(() => import("../pages/prediction/FriendPrediction")));
const FriendProfile = Loadable(lazy(() => import("../pages/dashboard/FriendProfile")));
const LoginInvite = Loadable(lazy(() => import("../pages/LoginInvite/LoginInvite")));
const NewsFeeds = Loadable(lazy(() => import("../pages/dashboard/NewsFeeds")));
const FriendsChat = Loadable(lazy(() => import("../pages/dashboard/FriendsChat")));






// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PrivateRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/",
      element: <Competition />,
    },
    {
      path: "/news-feed",
      element: <NewsFeeds />,
    },
    {
      path: "/login",
      element: <LoginInvite />,
    },
    {
      path: "/standings",
      element: <Standings />,
    },
    {
      path: "/playerStats",
      element: <PlayerState />,
    },
    {
      path: "/myPrediction",
      element: <MyPrediction />,
    },
    {
      path: "/myprofile",
      element: <MyProfile />,
    },
    {
      path: "/subscription",
      element: <Subscription />,
    },
    {
      path: "/friendPrediction",
      element: <FriendPrediction />,
    },
    {
      path: "/friendProfile",
      element: <FriendProfile />,
    },
    {
      path: "/friendChat",
      element: <FriendsChat />,
    },
  ],
};

export default PrivateRoutes;
