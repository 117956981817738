import { lazy } from "react";

// project imports

import "../Mobile/assets/css/mobile.css"


import Loadable from "../components/loader/Loadable";
import MobileAuthLayout from "../layout/MobileAuthLayout";





const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));

const MobileDashboard = Loadable(lazy(() => import("../Mobile/MobileDashboard")));
const ChatMobile = Loadable(lazy(() => import("../Mobile/ChatMobile")));
const ChatDetail = Loadable(lazy(() => import("../Mobile/ChatDetail")));
const TeamOverview = Loadable(lazy(() => import("../Mobile/TeamOverview")));


const CreateCompetitions = Loadable(lazy(() => import("../Mobile/CreateCompetitions")));
const MobileMyPrediction = Loadable(lazy(() => import("../Mobile/MobileMyPrediction")));

const Chat = Loadable(lazy(() => import("../Mobile/Chat")));
const PageOffcanvas = Loadable(lazy(() => import("../Mobile/PageOffcanvas")));
const MyProfile = Loadable(lazy(() => import("../Mobile/MyProfile")));
const MobileStanding = Loadable(lazy(() => import("../Mobile/MobileStanding")));
const MyInfo = Loadable(lazy(() => import("../Mobile/MyInfo")));
const MobileNewsFeed = Loadable(lazy(() => import("../Mobile/MobileNewsFeed")));
const MobileFriendPrediction = Loadable(lazy(() => import("../Mobile/MobileFriendPrediction")));
const MobileCompetitions = Loadable(lazy(() => import("../Mobile/MobileCompetitions")));

const CreatePost = Loadable(lazy(() => import("../Mobile/CreatePost")));
const MobileInviteFriends = Loadable(lazy(() => import("../Mobile/MobileInviteFriends")));
const Subscription = Loadable(lazy(() => import("../Mobile/Subscription")));
const FriendsProfile = Loadable(lazy(() => import("../Mobile/FriendsProfile")));
const MobileFriendChat = Loadable(lazy(() => import("../Mobile/MobileFriendChat")));
const MobilePlayerState = Loadable(lazy(() => import("../Mobile/MobilePlayerState")));









// ==============================|| AUTHENTICATION ROUTING ||============================== //





const MobilePrivateRoute = {
    path: "/",
    element: <MobileAuthLayout />,
    children: [
        // {
        //     path: "/", element: <MobileDashboard />,
        // },
        {
            path: "/", element: <MobileCompetitions />,
        },
        {
            path: "/ChatMobile", element: <ChatMobile />,
        },
        {
            path: "/ChatDetail", element: <ChatDetail />,
        },
        {
            // path: "/playerStats", element: <TeamOverview />,
        },
        {
            path: "/playerStats", element: <MobilePlayerState />,
        },
        {
            path: "/createCompetitions", element: <CreateCompetitions />,
        },
        {
            path: "/friendChat", element: <MobileFriendChat />,
        },
        {
            path: "/myPrediction", element: <MobileMyPrediction />,
        },
        {
            path: "/offcanvas", element: <PageOffcanvas />,
        },
        {
            path: "/Chat", element: <Chat />,
        },
        {
            path: "/myProfile", element: <MyProfile />,
        },
        {
            path: "/myInfo", element: <MyInfo />,
        },
        {
            path: "/Standing", element: <MobileStanding />,
        },
        {
            path: "/news-feed", element: <MobileNewsFeed />,
        },
        {
            path: "/friendPrediction", element: <MobileFriendPrediction />,
        },
        {
            // path: "/competitions", element: <MobileCompetitions />,
        },
        {
            path: "/subscription", element: <Subscription />,
        },
        {
            path: "/createPost", element: <CreatePost />,
        },
        {
            path: "/inviteFriends", element: <MobileInviteFriends />,
        },
        {
            path: "/friendProfile", element: <FriendsProfile />,
        },
    ],
};

export default MobilePrivateRoute;
