import io from "socket.io-client";


// const Socket = () => {
//     return socket;
// }
// Socket()


// const Socket = (function(){
//     console.log('socket connected with server ')
//     return 
// })()

export default io.connect(process.env.REACT_APP_SOCKET_NODE_URL, { transport: "websocket" });
