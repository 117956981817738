import React from 'react'
import { useLocation } from 'react-router-dom'
import MobileFooter from './Mobilefooter'

const MobileCommonFooter = () => {
    const location = useLocation()
    var isURL = ['/friendChat']
    return (
        <>
            {!isURL?.includes(location.pathname) && <MobileFooter/>  }
        </>
    )
}

export default MobileCommonFooter