
import { useSelector } from 'react-redux'
import { Outlet } from "react-router-dom";
import Loader from "react-loader";
import MobileHeader from '../Mobile/component/MobileHeader';
import MobileCommonFooter from '../Mobile/component/MobileCommonFooter';
import { useEffect, useRef } from 'react';







const MobileAuthLayout = () => {
  const scrollInto = useRef(null)
  const { loader } = useSelector((state) => state?.dashboard);

  var options = {
    lines: 13,
    length: 20,
    width: 10,
    radius: 30,
    scale: 1.00,
    corners: 1,
    color: '#06bd53',
    opacity: 0.5,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: true,
    hwaccel: true,
    position: 'absolute'
  };

  useEffect(() => {
    window.scrollTo(0,1)

  }, [])



  return (
    <>
      <Loader loaded={!loader} options={options} className="spinner" />
      <div className="bottom_space" ref={scrollInto}>
        <MobileHeader />
        <div className="main" id="main">
          <Outlet />
        </div>
        <MobileCommonFooter />
      </div>
    </>
  );

};

export default MobileAuthLayout;
