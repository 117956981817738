import React, { useState, useEffect } from 'react'
import Session from "../../utils/session";
import { useDispatch, useSelector } from "react-redux";
import { getCommentsList, setLoading, getPostList } from '../../redux/actions/DashboardActions'

import {
  GET_COMMENT_LIST_DISPATCH
} from "../../redux/constant/Dashboard";


import moment from 'moment';
import { Accordion } from 'react-bootstrap';
import NastedCommentList from './NastedCommentList';
import Socket from "../../Socket";


const Comments = ({ item, i }) => {
  const dispatch = useDispatch();
  const user = Session.getSession("token");
  const ProfilePic = Session.getSession("ProfilePic");
  // const commentList = useSelector((state) => state?.dashboard?.commentList);

  const [index, setIndex] = useState('');
  const [isShow, setIsShow] = useState(false);




  const handelShowBox = (indx) => {
    setIndex(indx)
    setIsShow(!isShow)
  }

  const handelLike_comment = (data) => () => {
    var socketData = { post_id: item?.id, comment_id: data?.id, user_id: user?.UserID }
    if (data?.isLiked === '0') {
      Socket.emit("post_comment_liked", socketData);
    } else {
      Socket.emit("post_comment_unliked", socketData);
    }
  };




  return (
    <>
      {
        item?.comment !== '' && item?.comment.map((ele, indx) => (
          <div className="comment-sec" key={indx}>
            <div className="feed-sec-scoller">
              <ul className="list-unstyled">
                <li>
                  <div className="d-flex">
                    <figure className="middle-circle">


                      {
                        ele?.user_image == "" ? <img src={process.env.REACT_APP_IMAGE_URL + user?.ProfilePic} alt="" /> : <img src={process.env.REACT_APP_IMAGE_URL + item?.user_image} alt="" />
                      }
                    </figure>
                    <figcaption>
                      <div className="figcaption">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <h4>{ele?.user_name}</h4>
                          <p style={{ color: '#afafaf', fontSize: 10 }}> {moment(ele?.created_at).format('DD/MM/yy, hh:mm A')} </p>
                        </div>
                        <p className="mb-0">
                          {ele?.comment}
                        </p>
                      </div>
                      <NastedCommentList post={item} comment={ele} indx={indx} handelLike_comment={handelLike_comment} handelShowBox={handelShowBox} />
                    </figcaption>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        ))
      }

    </>
  )
}

export default Comments