import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import axios from 'axios'
import _ from 'lodash';
import Session from "../../utils/session";


import { LOADER } from '../constant/Dashboard'
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, FORGOT_PASSWORD, VERIFY_OTP, CHANGE_PASSWORD, LOGOUT } from '../constant/Auth'
import { toast, ToastContainer } from "react-toastify";
import  Store  from "../Store";
import { io } from 'socket.io-client';



export const loginUser = (data) => async (dispatch) => {

    try {
        let payload = {
            'Keyword': data?.email,
            'Password': data?.password,
            'DeviceType': 'Native',
            'Source': 'Direct',
        }
        const res = await helper.api(apiPath.login, 'post', payload)
        console.log('res', res?.ResponseCode === '200');

        if (res?.ResponseCode === '200') {
            //  var socket = io.connect('http://192.168.1.117:5000', { query: res?.Data })
            
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return true
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.Message);
            return false
        }

    } catch (error) {
        console.log('error in login api::', error)
    }

}

export const logout = (data) => async (dispatch,getState) => {

    try {
        dispatch({
            type: LOADER,
            payload: true
        })
        const user = Session.getSession("token");
        let formData = new FormData();
        formData.append('SessionKey',  user?.SessionKey)

        const res = await helper.api(apiPath.logout, 'post', formData)

        if (res?.ResponseCode === '200') {
           
            dispatch({
                type: LOGOUT_SUCCESS,
                payload: res
            })
          setTimeout(() => {
            dispatch({
                type: LOGOUT,
                payload: ''
            })
          }, 100);
           
            dispatch({
                type: LOADER,
                payload: false
            })
            return true
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            return false
        }

    } catch (error) {
        console.log('error in logout api::', error)
    }

}

export const forgotPassword = (data) => async (dispatch) => {
    try {
        let payload = {
            "type": "Email",
            "Keyword": data?.email
        }
        const res = await helper.api(apiPath.forgotPassword, 'post', payload)

        if (res?.ResponseCode === '200') {
            dispatch({
                type: FORGOT_PASSWORD,
                payload: data?.email
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.Message);
            return true
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.Message);
            return false
        }

    } catch (error) {
        console.log('error in login api::', error)
    }

}
export const verifyOtp = (data) => async (dispatch) => {

    try {
        let payload = {
            "Password": data?.password,
            "confirmPass": data?.confirm_password,
            "OTP": data?.OTP
        }
        const res = await helper.api(apiPath.verifyOtp, 'post', payload)

        console.log('verify otpppp==>>>>', res)
        if (res?.ResponseCode === '200') {
            dispatch({
                type: VERIFY_OTP,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.Message);
            return true
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.Message);
            return false
        }

    } catch (error) {
        console.log('error in login api::', error)
    }

}
export const changePassword = (data) => async (dispatch) => {
    const user = Session.getSession("token");
    try {
        let formData = new FormData();
        formData.append('SessionKey',  user?.SessionKey)
        formData.append('CurrentPassword', data?.current_password)
        formData.append('Password', data?.password)

        const res = await helper.api(apiPath.changePassword, 'post', formData)

        if (res?.ResponseCode === '200') {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.Message);
            return true
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.Message);
            return false
        }

    } catch (error) {
        console.log('error in change password api::', error)
    }

}

export const socialLogin = (data) => async (dispatch) => {

    try {
        
        const res = await helper.api(apiPath.socialLogin, 'post', data)
        console.log('res', res?.ResponseCode === '200');

        if (res?.ResponseCode === '200') {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return true
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.Message);
            return false
        }

    } catch (error) {
        console.log('error in login api::', error)
    }

}