import React, { useState } from "react";
import { useEffect } from "react";

import { Modal, Button, Form, Tabs, Tab, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_MODAL } from "../../redux/constant/Dashboard";
import { useForm } from "react-hook-form";
import moment from "moment/moment";
import Session from "../../utils/session";



import { setLoading, createPostAction, getPostList, updatePost } from '../../redux/actions/DashboardActions'

import io from "socket.io-client";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";

const socket = io.connect(process.env.REACT_APP_SOCKET_NODE_URL, { transport: "websocket" });

const CreatePost = ({ visible, setVisible, postDetails }) => {
    const imageURL = process.env.REACT_APP_IMAGE_URL;
    const ProfilePic = Session.getSession("ProfilePic");

    const dispatch = useDispatch();

    const [values, setValues] = useState({ image: "", description: postDetails !== null ? postDetails?.description : "" })
    const [prevew, setPrevew] = useState(false)
    const [err, setErr] = useState({})
    const [fileType, setFileType] = useState(null)

    var supportedVideFormat = ['3gp', 'mp4', 'mov', 'quicktime', '3gpp',]



    const handleClose = () => {
        setVisible(false)
        clearAllState()
    };
    const clearAllState = () => {
        setValues({})
        setPrevew(false)
        setErr({})
    };

    const handelChange = (e) => {
        setErr({})
        var target = e.target
        if (target.name === 'image') {
            let type = e.target.files[0]?.type.split('/')
            console.log('file type is video', e.target.files[0]);
            if (type[0] === 'video') {
                if (supportedVideFormat.includes(type[1])) {
                    var fileSize = Number(target.files[0].size) / 1048576
                    console.log('fileSize', fileSize);
                    if (fileSize <= 10) {
                        setFileType('video')
                        console.log('file type is video', type[1]);
                        setPrevew(URL.createObjectURL(e.target.files[0]))
                    } else {
                        toast.error('Please Upload video upto 10MB in size')
                    }
                } else {
                    toast.error(`${type[1]} formate of video are not supported, please upload other video formate`)
                }
            } else {
                setFileType('image')
                setPrevew(URL.createObjectURL(e.target.files[0]))

            }
        }
        // const isImage = target.name == 'image' ? e.target.files[0].type.match(/image/) : null;

        //vide type = video/webm, video/mp4



        // if (target.name == 'image' && !isImage) {
        //     alert('Please upload supported attachment file format (.png, .jpeg, .jpg).')
        //     return
        // }
        setValues({ ...values, [target.name]: target.name == 'image' ? e.target.files[0] : e.target.value })
    }

    const createPost = async () => {
        console.log('values', values)
        if (postDetails !== null) {
            values['id'] = postDetails?.id
        }
        if (values?.description || values?.image) {
            dispatch(setLoading(true));
            const result = await dispatch(postDetails !== null ? updatePost(values) : createPostAction(values));

            if (result) {
                socket.emit("post_created")
                // dispatch(getPostList());
                handleClose()
            }
        } else {
            setErr({ err: 'All Field Are Required!' })

        }
    }


    useEffect(() => {
        socket.off('post_success').on('post_success', () => {
            dispatch(getPostList());
        })
        if (postDetails !== null) {
            let data = {
                description: postDetails?.description,
            }
            setValues(data)
        }
    }, [postDetails])

    const IsImgUrl = (url) => {
        let data = url?.split(".")
        var result = data[data?.length - 1]
        if (supportedVideFormat.includes(result)) {
            return true
        } else {
            return false
        }
    }




    return (
        <div>
            <Modal
                centered
                show={visible}
                onHide={handleClose}
                className="common-modal post_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="pick_header">
                        <h3>{postDetails !== null ? 'Update Post' : 'Create Post'}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-4">
                    <div className="modal-common-body text-center position-relative mt-0">
                        <Form>
                            <Form.Group
                                className="post_comment w-100 "
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <div className="d-flex align-items-start">
                                    {/* <img src="./assets/images/oval.svg" alt="" /> */}
                                    <img className="user_chat_image" src={imageURL + ProfilePic} alt="" />
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Write your post here..."
                                        className="border-0 rounded-0 h-100"
                                        name="description"
                                        value={values?.description}
                                        onChange={handelChange}
                                    />
                                </div>

                                <div className="pic_upload">
                                    <div className="pic_img">
                                        <input type="file" name='image' onChange={handelChange} />
                                        {/* <ReactPlayer
                                            url={prevew}
                                            width='100%'
                                            height='60%'
                                            controls={true}
                                        /> */}
                                        <span>
                                            {
                                                prevew ? fileType === 'video'
                                                    ? <ReactPlayer url={prevew} width='100%' height='40%' controls={false} playing={false} />
                                                    : <img src={prevew} alt='' height='80px' /> : (postDetails !== null && postDetails !== undefined) ? (IsImgUrl(postDetails?.image)
                                                        ? <ReactPlayer url={imageURL + postDetails?.image} width='100%' height='40%' controls={false} playing={false} />
                                                        : <img src={imageURL + postDetails?.image} alt='' height='80px' />)
                                                    : <img src="./assets/images/pic_upload.png" alt="" />
                                            }


                                        </span>

                                        <h5>Add Photo</h5>
                                        <i>or drag here</i>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>

                <Modal.Footer className="pt-0">
                    {err?.err &&
                        <small className="form-text text-danger help-block">{err.err}</small>
                    }
                    <Button className="theme-btn" onClick={createPost}>
                        Post
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreatePost;
