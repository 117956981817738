import { combineReducers } from 'redux'
import LoginReducer from './AuthReduer';

import DashboardReducer from './DashboardReducer'
import PlayersReducer from './PlayerReducer'
import RegisterReducer from './RegisterReducer';
import ProfileReducer from './ProfileReducer';
import SubscriptionReducer from './SubscriptionReducer';
import PredictionReducer from './PredictionReducer.';
import CompetitionReducer from './CompetitionReducer';





// const reducers = {
//     dashboard: DashboardReducer,
//     players: PlayersReducer,
//     auth: LoginReducer,
//     user:RegisterReducer,
//     profile:ProfileReducer,
//     subscribe:SubscriptionReducer,
//     prediction:PredictionReducer,
//     competition:CompetitionReducer,
// };

// export default reducers;


const appReducer = combineReducers({
  dashboard: DashboardReducer,
  players: PlayersReducer,
  auth: LoginReducer,
  user: RegisterReducer,
  profile: ProfileReducer,
  subscribe: SubscriptionReducer,
  prediction: PredictionReducer,
  competition: CompetitionReducer,
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  const stateCopy = action.type === "LOGOUT" ? '' : { ...state }
  
  return appReducer(stateCopy, action)
}
