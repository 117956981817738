import { useEffect, useState } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
// routes
import MainRoutes from "./MainRoutes";
import PrivateRoutes from "./PrivateRoutes";
import CommonRoutes from "./CommonRoutes";
import { useDispatch, useSelector } from "react-redux";
import MobilePrivateRoute from "./MobilePrivateRoute";
import { io } from "socket.io-client";
import Session from "../utils/session";




// ==============================|| ROUTING RENDER ||============================== //



export default function CombineRoutes() {
  const navigate = useNavigate()
  const Auth = useSelector((state) => state?.auth?.auth);
  const location = useLocation()
  const [isMobile, setIsMobile] = useState(false)


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 500) {
        setIsMobile(true)
      } else {
        setIsMobile(false)

      }
    }

    window.addEventListener('resize', handleResize)
  }, [location?.pathname])




  const [width, setWidth] = useState(window.innerWidth);
  let mobile = width <= 768;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const user = Session.getSession('package')

  useEffect(() => {
    // let socket = io.connect('http://192.168.1.117:5000', { query: user })
  }, []);





  useEffect(() => {
    if (!Auth) {
      location?.pathname === '/' && navigate('/home')
    }
    // console.log('useEffect call and check auth routes======================>>>>>>>>>>>>>>',location?.pathname)
  }, [location?.pathname]);
  return useRoutes(
    [
      Auth ? mobile ? MobilePrivateRoute : PrivateRoutes : MainRoutes,
      // Auth ? PrivateRoutes : MainRoutes,
      CommonRoutes
    ], process.env.BASENAME
  );
}

