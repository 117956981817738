import React from 'react'
import { useEffect } from 'react';
import { useRoutes, useNavigate, useLocation } from "react-router-dom";


const NotFound = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/')
    }, [])
    

}

export default NotFound