const apiPath = {
  //auth paths
  login: process.env.REACT_APP_API_URL + "/signin",
  socialLogin: process.env.REACT_APP_API_URL + "/Apis/socialLogin ",
  logout: process.env.REACT_APP_API_URL + "/signin/signout",
  forgotPassword: process.env.REACT_APP_API_URL + "/recovery",
  verifyOtp: process.env.REACT_APP_API_URL + "/recovery/setPassword",
  changePassword: process.env.REACT_APP_API_URL + "/users/changePassword",
  getNotificationList: process.env.REACT_APP_API_URL + "/notifications",
  getNotificationCount: process.env.REACT_APP_API_URL + "/notifications/getNotificationCount",
  readNotification:
    process.env.REACT_APP_API_URL + "//Notifications/markRead_post",
  readNotification: process.env.REACT_APP_API_URL + "/Notifications/markRead",
  readAllNotification:
    process.env.REACT_APP_API_URL + "/Notifications/markAllRead",

  //side post
  getPosts: process.env.REACT_APP_API_URL + "/Apis/getPost",
  createPost: process.env.REACT_APP_API_URL + "/Apis/createPost",
  updatePost: process.env.REACT_APP_API_URL + "/Apis/editPost",
  deletePost: process.env.REACT_APP_API_URL + "/Apis/deletePost",


  getComments: process.env.REACT_APP_API_URL + "/Apis/getComment",

  //match comment and likes
  getMatch_comments_likes:
    process.env.REACT_APP_API_URL + "/Apis/getMatchComment",

  //register paths
  userRegister: process.env.REACT_APP_API_URL + "/signup",

  //Profile paths
  getProfileDetails: process.env.REACT_APP_API_URL + "/users/getProfile",
  getUserFriendList: process.env.REACT_APP_API_URL + "/users/get_friends_list",
  updateProfile: process.env.REACT_APP_API_URL + "/users/updateUserInfo",
  getCountryList: process.env.REACT_APP_API_URL + "/Apis/getCountryList",
  getStateDetails: process.env.REACT_APP_API_URL + "/Apis/getDashboardData",


  getUserPredictionList: process.env.REACT_APP_API_URL + "/Apis/getUserPredictionList", //pending


  //Subscription paths
  subscriptionList:
    process.env.REACT_APP_API_URL + "/Utilities/getSubscriptionsts",
  initiateSubscribe:
    process.env.REACT_APP_API_URL + "/users/initiateSubscription",
  purchaseSubscription:
    process.env.REACT_APP_API_URL + "/users/purchaseSubscription",

  //Dashboard
  getLeagues: process.env.REACT_APP_API_URL + "/apis/getLeagues",
  getPrizes: process.env.REACT_APP_API_URL + "/apis/getPrizes",

  getWeeks: process.env.REACT_APP_API_URL + "/football/getWeeks",
  getMatches: process.env.REACT_APP_API_URL + "/football/getMatches",
  getTeamDetails: process.env.REACT_APP_API_URL + "/teams/getTeamDetails",
  getHead2Head: process.env.REACT_APP_API_URL + "/Apis/getHead2Head",

  joinCompetition: process.env.REACT_APP_API_URL + "/Groups/JoinPublicGroup",
  addPrediction: process.env.REACT_APP_API_URL + "/Football/matchPrediction",
  getValidationDetails: process.env.REACT_APP_API_URL + "/Apis/getUserGroupCount",
  


  // Competition Path

  getUserList: process.env.REACT_APP_API_URL + "/users/search",

  getCompetition: process.env.REACT_APP_API_URL + "/football/getCompetitions",

  getOldCompetitionList: process.env.REACT_APP_API_URL + "/groups/old_list",
  getCurrentCompetitionList: process.env.REACT_APP_API_URL + "/groups/current_list",


  addCompetition: process.env.REACT_APP_API_URL + "/groups/add",
  editPrivateGroup: process.env.REACT_APP_API_URL + "/groups/editPrivateGroup",
  deletePriavteGroup: process.env.REACT_APP_API_URL + "/groups/deletePriavteGroup",


  getGroupDetail: process.env.REACT_APP_API_URL + "/groups/GetGroupDetail",

  removeUserPrediction: process.env.REACT_APP_API_URL + "/Apis/deletePrediction",
  getFriendPrediction: process.env.REACT_APP_API_URL + "/Apis/getFriendPredictions",


  searchFriend:
    process.env.REACT_APP_API_URL + "/groups/SearchUsersGroupinvite",

  groupJoinRequest:process.env.REACT_APP_API_URL + "/groups/RequestForJoinGroup",
  getInviteGroupInfoByCode:process.env.REACT_APP_API_URL + "/Groups/getInviteGroupInfoByCode",
  

  inviteRequestNonExist:
    process.env.REACT_APP_API_URL + "/groups/InviteRequestNonExist",

  //Standing Urls

  getTeamList: process.env.REACT_APP_API_URL + "/football/getTeams",

  //player module
  getPlayers: process.env.REACT_APP_API_URL + "/football/getPlayers",



  //friend - chat
  searchFriendChat: process.env.REACT_APP_API_URL + "/Groups/SearchUsersGroupinvite",
  sendFriendRequest: process.env.REACT_APP_API_URL + "/Chat/sendFriendRequest",
  acceptFriendRequest: process.env.REACT_APP_API_URL + "/Chat/acceptFriendRequest",
  rejectFriendRequest: process.env.REACT_APP_API_URL + "/Chat/rejectFriendRequest",
  friendList: process.env.REACT_APP_API_URL + "/Apis/SearchMyFriendList",
  chattingList: process.env.REACT_APP_API_URL + "/Apis/getUserChat",


  //invitation for competition
  acceptInviteRequest: process.env.REACT_APP_API_URL + "/Groups/acceptGroupJoinRequest",
  rejectGroupJoinRequest: process.env.REACT_APP_API_URL + "/Groups/rejectGroupJoinRequest",



  //Prediction
  getPredictionList: process.env.REACT_APP_API_URL + "/Football/getPredictions",
  getMyPredictionList: process.env.REACT_APP_API_URL + "/Football/getMyPrediction",
  getPredictionComments: process.env.REACT_APP_API_URL + "/Apis/getGroupChat",
  InviteByLink: process.env.REACT_APP_API_URL + "/Apis/InviteByLink",




  //static pages
  about_us: process.env.REACT_APP_API_URL + "/utilities/getPage",
  contact_us: process.env.REACT_APP_API_URL + "/Utilities/contact",

  getGlobalPredictionList: process.env.REACT_APP_API_URL + "/Apis/getPredictionGlobalRanking",

};
export default apiPath;
