export const GET_LEAGUES_LIST = "GET_LEAGUES_LIST";
export const GET_WEEKS_LIST = 'GET_WEEKS_LIST'
export const GET_MATCH_LIST = 'GET_MATCH_LIST'
export const LOADER = 'LOADER'
export const SET_LEAGUE_ID = 'SET_LEAGUE_ID'
export const LEAGUE_ID='LEAGUE_ID'
export const GET_TEAM_DETAILS = 'GET_TEAM_DETAILS'
export const SHOW_MODAL='SHOW_MODAL'
export const NOTIFICATION_LIST='NOTIFICATION_LIST'
export const NOTIFICATION_COUNT='NOTIFICATION_COUNT'
export const SHOW_JOIN_MODAL='SHOW_JOIN_MODAL'
export const COMPETETION_DETAILS='COMPETETION_DETAILS'
export const HEAD_2_HEAD_DETAILS='HEAD_2_HEAD_DETAILS'
export const COMPETITION_GUID='COMPETITION_GUID'
export const GET_MATCH_COMMENT_LIST='GET_MATCH_COMMENT_LIST'
export const GET_POST_LIST = "GET_POST_LIST";
export const GET_COMMENT_LIST = "GET_COMMENT_LIST";
export const GET_COMMENT_LIST_DISPATCH = "GET_COMMENT_LIST_DISPATCH";
export const SHOW_ADD_PREDICTION_MODAL = "SHOW_ADD_PREDICTION_MODAL";
export const TEAM_FLAG = "TEAM_FLAG";
export const WEEK_GUID = "WEEK_GUID"
export const OPEN_MATCH_CHAT='OPEN_MATCH_CHAT'
export const VALIDATION_LIST='VALIDATION_LIST'
export const IS_CHAT_OPEN='IS_CHAT_OPEN'
export const IS_CHAT_SCREEN='IS_CHAT_SCREEN'
export const ONLINE_USER='ONLINE_USER'
export const WEEK_INDEX='WEEK_INDEX'
export const MATCH_STATUS_UPDATE='MATCH_STATUS_UPDATE'
export const PRIZE_DETAILS='PRIZE_DETAILS'
export const PAGE_RELOAD='PAGE_RELOAD'


// Mobile Websit Constant
export const OPEN_LEAGUE_MODAL='OPEN_LEAGUE_MODAL'


