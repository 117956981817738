import {
  COUNTRY_LIST,
  PROFILE_DETAILS,
  USER_FRIEND_LIST,
  USER_STATE,
} from "../constant/Profile";

let initialState = {
  profileDetails: {},
  userFriendList: {},
  countryList: [],
  userState:{}
};

const ProfileReducer = function (state = initialState, action) {
  switch (action.type) {
    case PROFILE_DETAILS:
      return {
        ...state,
        profileDetails: action?.payload?.Data,
      };

    case USER_FRIEND_LIST:
      return {
        ...state,
        userFriendList: action?.payload?.Data,
      };

    case COUNTRY_LIST:
      return {
        ...state,
        countryList: action?.payload,
      };

      case USER_STATE:
      return {
        ...state,
        userState: action?.payload?.Data,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
