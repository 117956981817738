import { lazy } from "react";


import Loadable from "../components/loader/Loadable";
import BaseLayout from "../layout/BaseLayout";

//import component
const Home = Loadable(lazy(() => import("../pages/auth/Home")));
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));

const ContactUs = Loadable(lazy(() => import("../pages/static/ContactUs")));


// All common routes
const MainRoutes = {
    path: "/",
    element: <BaseLayout />,
    children: [
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/register",
            element: <Register />,
        },
    ],
};

export default MainRoutes;