export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const VERIFY_OTP = 'VERIFY_OTP'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const LOGOUT = 'LOGOUT'
export const ONLINE_USER_LIST = 'ONLINE_USER_LIST'



