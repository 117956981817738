import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import React from "react";
import Loader from "react-loader";

const BaseLayout = () => {
  const { loader } = useSelector((state) => state?.dashboard);

  var options = {
    lines: 13,
    length: 20,
    width: 10,
    radius: 30,
    scale: 1.00,
    corners: 1,
    color: '#06bd53',
    opacity: 0.5,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: true,
    hwaccel: true,
    position: 'absolute'
  };

  return (
    <>
      <Loader loaded={!loader} options={options} className="spinner" />
      <div style={{ opacity: loader && '0.3' }} >
      <Outlet />
      </div>
    </>
  );
};

export default BaseLayout;
