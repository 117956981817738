import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { changePassword } from "../../redux/actions/AuthActions";
import { setLoading } from "../../redux/actions/DashboardActions";

import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ChangePasswordModel = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onBlur",
    souldFocusError: true,
    defaultValues: { password: "" },
  });
  const [newPassword, setNewPassword] = useState("");

  const handelChangePassword = async (data) => {
    dispatch(setLoading(true));
    const result = await dispatch(changePassword(data));
    if (result) setVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        show={visible}
        onHide={() => setVisible(false)}
        className="common-modal team_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="pick_header">
            <h3>Change Password</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 pt-md-5">
          <div className="modal-common-body text-center mt-0">
            <Form onSubmit={handleSubmit(handelChangePassword)}
              method="post">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <img
                      src="./assets/images/lock.svg"
                      alt=""
                      className="me-4"
                    />
                    <Form.Group
                      controlId="formCurrentPassword"
                      className="w-100"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Current Password"
                        className="form-controls text-box"
                        name="current_password"
                        {...register("current_password", {
                          required: true,
                          minLength: 6,
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,20})/,
                        })}
                      />
                      {errors?.current_password?.type === "required" && (
                        <small className="form-text text-danger help-block">
                          Please enter current password.
                        </small>
                      )}
                      {errors?.current_password?.type === "minLength" && (
                        <small className="form-text text-danger help-block">
                          current passwords should have minimum 6 characters.
                        </small>
                      )}
                      {errors?.current_password?.type === "pattern" && (
                        <small className="form-text text-danger help-block">
                          current Password must contain at least one capital
                          character,one small character, one number, one special
                          character.
                        </small>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="col-12">
                  {" "}
                  <div className="d-flex align-items-center">
                    <img
                      src="./assets/images/lock.svg"
                      alt=""
                      className="me-4"
                    />
                    <Form.Group
                      controlId="formBasicPassword"
                      className="w-100"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        className="form-controls text-box"
                        name="password"
                        {...register("password", {
                          required: true,
                          minLength: 6,
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,20})/,
                        })}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      {errors?.password?.type === "required" && (
                        <small className="form-text text-danger help-block">
                          Please enter password.
                        </small>
                      )}
                      {errors?.password?.type === "minLength" && (
                        <small className="form-text text-danger help-block">
                          Passwords should have minimum 6 characters.
                        </small>
                      )}
                      {errors?.password?.type === "pattern" && (
                        <small className="form-text text-danger help-block">
                          Password must contain at least one capital character,one
                          small character, one number, one special character.
                        </small>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex  align-items-center">
                    <img
                      src="./assets/images/lock.svg"
                      alt=""
                      className="me-4"
                    />
                    <Form.Group controlId="formNewPassword" className="w-100">
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        className="form-controls text-box"
                        name="confirm_password"
                        {...register("confirm_password", {
                          required: true,
                          validate: (value) =>
                            value === newPassword || "New Passwords do not match.",
                        })}
                      />
                      {errors.confirm_password?.type === "required" && (
                        <small className="form-text text-danger help-block">
                          Please enter confirm password.
                        </small>
                      )}
                      {errors.confirm_password?.type === "validate" && (
                        <small className="form-text text-danger help-block">
                          Password and confirm password must be same.
                        </small>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button className="theme-btn" type="submit" >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangePasswordModel;
