import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { IS_CHAT_SCREEN } from '../../redux/constant/Dashboard'

const MobileFooter = () => {
  const location = window.location.pathname
  const chatScreen = useSelector((state) => state?.dashboard?.isChatScreen);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleFriendChat = () => {
    if (chatScreen && chatScreen) {
      dispatch({
        type: IS_CHAT_SCREEN,
        payload: false
      })
    }
    navigate('/friendChat')
  }
  return (
    <>
      <div className='mobile_footer px-4 d-flex align-items-center'>
        <Link to="/" className={`award ${(location === '/' || location === '/myPrediction') && 'active'}`}><img src="assets/mobileimages/awarddisactive.svg" className="green_icon" alt="" /><img src="assets/mobileimages/awarddis.svg" className='footer_light' alt="" /></Link>
        <a onClick={() => handleFriendChat()} className={`account ${location === '/friendChat' && 'active'}`}><img src="assets/mobileimages/account.svg" className='footer_light' alt="" /></a>
        {/* <Link ><img src="assets/mobileimages/awarddisactive.svg" className="green_icon" alt="" /><img src="assets/mobileimages/awarddis.svg" className='footer_light' alt="" /></Link> */}

        <Link to='/news-feed' className={`Feed-center ${(location === '/createPost' || location === '/news-feed') && 'active'}`}><img src="assets/mobileimages/feedactive.svg" className="green_icon" alt="" /><img src="assets/mobileimages/feed.svg" className='footer_light' alt="" /></Link>
        <Link to="/myProfile" className={`account ${location === '/myProfile' && 'active'}`}><img src="assets/mobileimages/accountactive.svg" className="green_icon" alt="" /><img src="assets/mobileimages/account.svg" className='footer_light' alt="" /></Link>
        <Link to="/playerStats" className={`shoese ${location === '/playerStats' && 'active'}`}><img src="assets/mobileimages/shoeseactive.svg" className="green_icon" alt="" /><img src="assets/mobileimages/shoese.svg" className='footer_light' alt="" /></Link>
      </div>
    </>
  )
}

export default MobileFooter