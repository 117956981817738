import React, { useState, useEffect, } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavDropdown, Button, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import {
  getList,
  getNotificationCount,
  getNotificationList,
  setSelectedLeague,
  getWeeks,
  setLoading,
  getPrizes,
} from "../redux/actions/DashboardActions";
import { logout } from "../redux/actions/AuthActions";
import helper from "../utils/helper";
import apiPath from "../utils/apiPath";
import userEvent from "@testing-library/user-event";
import Session from "../utils/session";
import { ChangePassword } from "../redux/actions/AuthActions";
import ChangePasswordModel from "../components/models/ChangePassword";
import { IS_JOIN_FROM_NOTIFICATION, SPONSORED_DATA } from "../redux/constant/CompetitionConstant";
import Socket from "../Socket";
import { PAGE_RELOAD } from "../redux/constant/Dashboard";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useSelector((state) => state?.auth?.auth);
  const leagueList = useSelector((state) => state?.dashboard?.dashboardList);
  const competion = useSelector((state) => state?.competition?.sponsoredData);
  const LeagueID = useSelector((state) => state?.dashboard?.LeagueID);
  const PrizeDetails = useSelector((state) => state?.dashboard?.prizeDetails);



  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const { Records, totalCount } = useSelector((state) => state?.dashboard.dashboardList);
  const [selectedValue, setSelectedValue] = useState(null);
  const [show, setShow] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const user = Session.getSession("token");
  const ProfilePic = Session.getSession("ProfilePic");
  const [competionDetails, setCompetionDetails] = useState(null);

  const notificationList = useSelector((state) => state?.dashboard?.notificationList);
  const notificationCount = useSelector(
    (state) => state?.dashboard?.notificationCount
  );

  const [visible, setVisible] = useState(false);
  


  useEffect(() => {
    if (auth) {
      dispatch(getList());
    }
    dispatch(getNotificationCount());
  }, [auth]);

 
 





  const handelSelect = (item) => () => {
    setSelectedValue(item);
    dispatch(getPrizes(item?.LeagueID))
    console.log(item);
    dispatch(setSelectedLeague(item));
    if (window?.location?.pathname === "/") {
      dispatch(setLoading(true));
      dispatch(getWeeks(item));
    }
  };



  useEffect(() => {
    // console.log(state, '==============>');
    setCompetionDetails(state)
  }, [window?.location?.pathname === '/myPrediction'])


  const handleNav = () => {
    document
      .getElementById("body-parent")
      .classList.toggle("left-sidebar-toggle");
  };

  const handleLogout = () => {
    setIsLogout(!isLogout)
  }

  const Logout = async () => {
    dispatch(setLoading(true));
    const result = await dispatch(logout());
    if (result) {
      setIsLogout(!isLogout)
      navigate("/login");
    }
  };


  const changePassword = async () => {
    setVisible(true);
  };

  const handleNotificationList = () => {
    if (show === false) {
      dispatch(getNotificationList());
    }
    // console.log("click on notification");
    setShow(!show);
    if (notificationList?.length > 0) {
      //call notification read api
    }
  };

  const handleReadNotification = async (item) => {
    console.log(item);
    let payloadData = {
      SessionKey: user?.SessionKey,
      NotificationID: item?.NotificationID,
    };
    let res = await helper.api(apiPath.readNotification, "post", payloadData);
    if (res.ResponseCode === "200") {
      dispatch(getNotificationList());
      return true;
    } else {
      console.log(res.Message);
      return false;
    }
  };

  const handlereadAllNotification = async () => {
    let payloadData = {
      SessionKey: user?.SessionKey,
    };
    let res = await helper.api(
      apiPath.readAllNotification,
      "post",
      payloadData
    );
    if (res.ResponseCode === "200") {
      dispatch(getNotificationCount());
      dispatch(getNotificationList());
    } else {
      console.log(res.Message);
    }
  };

  const handleSubscription = () => {
    navigate("/subscription");
  };

  const handleProfile = () => {
    navigate("/myprofile");
  };

  const handelAction = (type, item) => async () => {
    try {
      const data = {
        SessionKey: user?.SessionKey,
        ToUserGUID: item?.UserGUID,
      };
      let res = await helper.api(
        type === "accept"
          ? apiPath.acceptFriendRequest
          : apiPath.rejectFriendRequest,
        "post",
        data
      );
      if (res?.ResponseCode === "200") {
        toast.success(res.Message);
        const result = await handleReadNotification(item);
        if (result) {
          Socket.emit(`notification_send`, { UserGUID: item?.UserGUID });
          dispatch(getNotificationCount());
        }
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.log(
        "err in accept and reject friend request==>>>>>",
        error.message
      );
    }
  };

  const getGroupDetail = async (item) => {
    let formData = new FormData()
    formData.append('SessionKey', user?.SessionKey)
    formData.append('GroupID', item?.GroupID)
    formData.append('GroupInviteCode', item?.GroupInviteCode)


    let res = await helper.api(apiPath.getGroupDetail, "post", formData);
    if (res.ResponseCode === "200") {
      return res;
    } else {
      console.log(res.Message);
      return false;
    }
  };

  const handelInviteAction = (type, item) => async () => {
    try {
      if (type === "accept") {
        let result = await getGroupDetail(item);
        console.log(result);
        let state = result?.Data
        state['ToUserID'] = item?.ToUserID
        state['UserID'] = item?.UserID
        state['NotificationID'] = item?.NotificationID
        state['IsJoined'] = 'No'
        state['NotificationPatternGUID'] = 'RequestForJoinGroup'
        state['isPendingRequest'] = true
        state['GroupInviteCode'] = item?.GroupInviteCode

        dispatch({
          type: IS_JOIN_FROM_NOTIFICATION,
          payload: true
        })
        dispatch({
          type: SPONSORED_DATA,
          payload: state
        })
        document.querySelector(".dropdown-menu").classList.remove('show')
        dispatch({
          type: PAGE_RELOAD,
          payload: false,
        });
        navigate('/myPrediction', { state: state })
      } else {

        await handleReadNotification(item);
        dispatch(getNotificationCount());
        const data = {
          SessionKey: user?.SessionKey,
          GroupID: item?.GroupID,
          UserID: item?.ToUserID,
          ToUserID: item?.UserID,
        };

        let res = await helper.api(apiPath.rejectGroupJoinRequest, "post", data);
        if (res?.ResponseCode === "200") {
          toast.success(res.Message);

        } else {
          toast.error("Something Went Wrong");
        }
      }



    } catch (error) {
      console.log(
        "err in accept and reject friend request==>>>>>",
        error.message
      );
    }
  };

  const location = window?.location?.pathname

  // ***********socket useEffect****************
  useEffect(() => {
    Socket.on(`noti_${user?.UserGUID}`, (data) => {
      dispatch(getNotificationCount());
    });

    Socket.on(`noti_${user?.UserID}`, (data) => {
      dispatch(getNotificationCount());
    });
    // dispatch(getPrizes())
  }, [])

  useEffect(() => {
    // console.log(LeagueID,'======>>')
    // console.log('======>>')
    if (Records && LeagueID !== null) {
      let data = Records?.filter((item) => item?.LeagueID === LeagueID)
      // console.log(data);
      setSelectedValue(data[0])
    }
  }, [window?.location?.pathname === '/'])





  return (
    <>

      <ChangePasswordModel visible={visible} setVisible={setVisible} />
      {Records && (
        <div className="header d-flex align-items-center">

          <div className="header-left d-flex align-items-center">
            <button
              className="left_sidebar_toggle d-block d-lg-none"
              onClick={() => handleNav()}
            >
              <img src="./assets/images/menu.png" />
            </button>
            {(location === '/news-feed' || location === '/myPrediction' || location === '/friendProfile' || location === '/myprofile' || location === '/' || location === '/friendChat') ?
              location === '/myPrediction' &&
              <div className="predication_tag_row justify-content-normal ">
                <div className="d-flex flex-wrap align-items-center justify-content-between pe-2">
                  {competion?.SponserName !== '' &&
                    <div className="predication_tag_grid align-items-center">
                      <a className="sponsored-class">Sponsored By</a>
                      <div className="d-flex align-items-center mt-3">
                        <figure className="predication_tag mb-0 header-predication" style={{ background: 'white' }}>
                          <img src={imageURL + competion?.SponserLogo} alt="" />
                        </figure>
                        <div className="predication_value position-relative d-flex align-items-center ">
                          <h6 className="mb-0">{competion?.SponserName}</h6>

                        </div>
                      </div>
                    </div>}

                  <div className="d-flex flex-wrap align-items-center ms-4" style={{ marginTop: '45px' }}>
                    <a className="text-decoration-none text-white competition-prize competition-prize-second">
                      <img
                        src="./assets/images/gift-icon.svg"
                        alt=""
                        className="pe-2"

                      />
                      {competion?.Prizes?.map((item, i) => {
                        return (
                          <p key={i}>Prize {item?.PrizeNo} {competion?.Prizes && item?.PrizeTitle}</p>
                        )
                      })}


                    </a>
                  </div>



                </div>
              </div>

              :
              <NavDropdown
                title={
                  <div className="">
                    <img
                      src={
                        selectedValue !== null
                          ? imageURL + selectedValue?.LeagueFlag
                          : imageURL + Records[0]?.LeagueFlag
                      }
                      alt=""
                      height="25px"
                    />
                    <span>
                      {selectedValue?.LeagueName || Records[0]?.LeagueName}
                    </span>
                  </div>
                }
                id="basic-nav-dropdown"
              >
                {/* <NavDropdown.Item >
              {" "}
              <img src="./assets/images/drop-icon1.svg" alt="" /> All
            </NavDropdown.Item> */}
                <div className="inner-scoller" >

                  {Records?.length > 0 &&
                    Records.map((item, i) => (
                      <NavDropdown.Item

                        key={i}
                        className={
                          item?.LeagueName == selectedValue?.LeagueName
                            ? "active"
                            : ""
                        }
                        onClick={handelSelect(item)}
                      >
                        {" "}
                        <img src={imageURL + item?.LeagueFlag} />
                        {item?.LeagueName}
                      </NavDropdown.Item>
                    ))}
                </div>
              </NavDropdown>
            }
            {location === '/friendChat' && <h3>Friends</h3>}
          </div>
          {competion !== null && window.location.pathname === '/myPrediction' &&
            <div className="new-code mt-5 ms-3">
              <div className="predication_tag_row justify-content-normal flex-row">
                <div className="d-flex flex-wrap align-items-center justify-content-between pe-2">
                  <div className="predication_tag_grid d-flex align-items-center me-sm-5 me-2">
                    <figure className="predication_tag mb-0">
                      <img src={imageURL + competion?.LeagueFlag} alt="" />
                    </figure>
                    <div className="predication_value position-relative d-flex align-items-center">
                      <h6 className="mb-0">{competion?.GroupName}</h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>}

          {/* {window.location.pathname === '/' &&
            <div className="d-flex flex-wrap align-items-center ms-4" style={{ marginTop: '32px' }}>
              <a className="text-decoration-none text-white competition-prize competition-prize-second">
                <img
                  src="./assets/images/gift-icon.svg"
                  alt=""
                  className="pe-2"

                />
                {PrizeDetails !== null && PrizeDetails?.map((item, i) => {
                  return (
                    <p key={i}>Prize {item?.PrizeNo} {PrizeDetails && item?.PrizeTitle}</p>
                  )
                })}


              </a>
            </div>} */}

          <div className="ms-auto">
            <div className="header-right d-flex align-items-center">
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/contact-us">
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link to="/tips&faqs">
                    Tips & FAQs
                  </Link>
                </li>
              </ul>

              <div className="d-flex align-items-center">
                <NavDropdown className="notification-dropdown-wrapper"
                  title={
                    <div className="position-relative notification">
                      <img src="./assets/images/notification-icon.svg" alt="" />
                      {notificationCount !== 0 && (
                        <span>{notificationCount}</span>
                      )}

                    </div>
                  }
                  id="basic-nav-dropdown1"
                  onClick={() => handleNotificationList()}
                >
                  {/* {show && ( */}
                  <ul className="p-0 m-0 notifications_list">
                    <h2>Notifications</h2>
                    {notificationList?.Records?.length > 0 &&
                      <p onClick={() => handlereadAllNotification()} className='delete-notification-icon'>
                        <img src='./assets/images/delete-icon.svg' alt='' />
                      </p>
                    }
                    <ul className="list-unstyled">
                      {notificationList?.Records?.length > 0 &&
                        notificationList?.Records?.map((item, i) => {
                          return (
                            item?.StatusID !== "2" && (
                              <li
                                // onClick={() => handleReadNotification(item)}
                                key={i}
                                className="me-0 d-block mb-3"
                              >
                                {item?.NotificationPatternGUID ===
                                  "SendFriendRequest" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <h3>
                                      {item?.NotificationMessage}
                                      <span>Friend request</span>
                                    </h3>

                                    <div className="d-flex align-items-center">
                                      <Button
                                        onClick={handelAction("accept", item)}
                                        style={{
                                          borderRadius: "100%",
                                          marginLeft: 5,
                                        }}
                                      >
                                        {" "}
                                        <i class="fa-solid fa-check"></i>
                                      </Button>
                                      <Button
                                        onClick={handelAction("reject", item)}
                                        style={{
                                          borderRadius: "100%",
                                          background: "red",
                                          marginLeft: 5,
                                        }}
                                      >
                                        {" "}
                                        <i class="fa-solid fa-xmark"></i>
                                      </Button>
                                    </div>

                                  </div>
                                ) : item.NotificationPatternGUID === 'RequestForJoinGroup' ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <h3>{item?.NotificationMessage} </h3>

                                    <div className="d-flex align-items-center">
                                      <Button
                                        onClick={handelInviteAction("accept", item)}
                                        style={{
                                          borderRadius: "100%",
                                          marginLeft: 5,
                                        }}
                                      >
                                        {" "}
                                        <i class="fa-solid fa-check"></i>
                                      </Button>
                                      <Button
                                        onClick={handelInviteAction("reject", item)}
                                        style={{
                                          borderRadius: "100%",
                                          background: "red",
                                          marginLeft: 5,
                                        }}
                                      >
                                        {" "}
                                        <i class="fa-solid fa-xmark"></i>
                                      </Button>
                                    </div>


                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <h3>{item?.NotificationMessage} </h3>
                                  </div>
                                )
                                }
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </ul>
                  {/* )} */}
                </NavDropdown>

                <NavDropdown
                  title={
                    <div className="profile-outer">
                      <img src={imageURL + ProfilePic} alt="" />
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item onClick={handleProfile}>
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleSubscription}>
                    Subscription
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={changePassword}>
                    Change Password
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Modal
          centered
          show={isLogout}
          onHide={() => setIsLogout(!isLogout)}
          className="common-modal logout_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="pick_header">
              <h3>Logout</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <div className="modal-common-body text-center mt-0">
              <h5>Are you sure you want to quit?</h5>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button className="theme-btn" onClick={() => Logout()}>
              Quit now
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Header;
