import { lazy } from "react";


import Loadable from "../components/loader/Loadable";
import StaticLayout from "../layout/StaticLayout";
import NotFound from "../pages/static/NotFound";

//import component
const ContactUs = Loadable(lazy(() => import("../pages/static/ContactUs")));
const Scoring = Loadable(lazy(() => import("../pages/static/Scoring")));
const GameRules = Loadable(lazy(() => import("../pages/static/GameRules")));
const AboutUs = Loadable(lazy(() => import("../pages/static/AboutUs")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/static/PrivacyPolicy")));
const TermsConditions = Loadable(lazy(() => import("../pages/static/TermsConditions")));
const Disclamer = Loadable(lazy(() => import("../pages/static/Disclamer")));
const RulesPoints = Loadable(lazy(() => import("../pages/static/GameRules")));
const Home = Loadable(lazy(() => import("../pages/auth/Home")));
const FAQs = Loadable(lazy(() => import("../pages/static/FAQs")));



// All common routes
const CommonRoutes = {
    path: "/",
    element: <StaticLayout />,
    children: [
       
        {
            path: "/home",
            element: <Home />,
        },
        {
            path: "/contact-us",
            element: <ContactUs />,
        },
        {
            path: "/about-us",
            element: <AboutUs />,
        },
        {
            path: "/scoring",
            element: <Scoring />,
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicy />,
        },
        {
            path: "/terms-conditions",
            element: <TermsConditions />,
        },
        {
            path: "/disclamer",
            element: <Disclamer />,
        },
        {
            path: "/game-rules",
            element: <GameRules />,
        },
        {
            path: "/tips&faqs",
            element: <FAQs />,
        },
        {
            path: '*',
            element: <NotFound />,
        },

    ],
};

export default CommonRoutes;