import { GET_PLAYER_LIST, UNIQUE_LIST } from '../constant/Player'

let initialState = {
  playersList: {},
  uniquePlayer: []
};

const PlayersReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PLAYER_LIST:
      return {
        ...state,
        playersList: action?.payload?.Data,
      };
    case UNIQUE_LIST:
      return {
        ...state,
        uniquePlayer: action?.payload,
      };
    default:
      return state;
  }
};

export default PlayersReducer;
