import {
  SUBSCRIPTION,
  INITIATE_SUBSCRIBE,
} from "../constant/SubscriptionConstant";

let initialState = {
  subscription: {},
};

const SubscriptionReducer = function (state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTION:
      return {
        ...state,
        subscription: action?.payload?.Data,
      };

    default:
      return state;
  }
};

export default SubscriptionReducer;
