import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Offcanvas, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getList, getMatches, getNotificationCount, getPrizes, getWeeks, setLoading, setSelectedLeague } from "../../redux/actions/DashboardActions";
import { GET_MATCH_LIST, IS_CHAT_SCREEN, WEEK_GUID } from "../../redux/constant/Dashboard";
import menuIcon from '../../assets/mobileimages/mobilelist.svg'
import backArrow from '../../assets/mobileimages/back.png'
import avatarUrl from '../../assets/mobileimages/mobilelogo.svg'







const MobileHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const imageURl = process.env.REACT_APP_IMAGE_URL
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null);
  const [leagueList, setLeagueList] = useState(null);
  const location = useLocation()
  var isUrl = ['/', '/playerStats', '/standing'];
  var titleURL = ['/playerStats', '/friendPrediction'];

  const handleOpenLeagueModal = () => {
    console.log('check console, check console');
    setIsOpen(true)
  }
  const LeagueID = useSelector((state) => state?.dashboard?.LeagueID);



  const openLeagueModal = useSelector((state) => state?.dashboard?.openLeagueModal);


  const handleCloseLeagueModal = () => {
    setIsOpen(false)
  }


  const weekIndex = useSelector((state) => state?.dashboard?.weekIndex);

  const [selected, setSelected] = useState(0);
  const [loadCom, setLoadCom] = useState(false);
  const sliderRef = React.createRef()
  const auth = useSelector((state) => state?.auth?.auth);
  const chatScreen = useSelector((state) => state?.dashboard?.isChatScreen);




  const handelSelectWeeks = (item, i) => () => {
    dispatch(setLoading(true));
    // console.log('set week id==>>>>', i)
    setSelected(i);
    dispatch({
      type: WEEK_GUID,
      payload: item?.WeekGUID,
    });

    let WeekGUID = item?.WeekGUID;

    dispatch(getMatches(WeekGUID));
  };

  const getLeagueList = async () => {
    const res = await dispatch(getList());
    console.log(res)
    if (res?.Message === "Suceess") {
      setLeagueList(res?.Data?.Records)
    }
  }

  useEffect(() => {
    leagueList && dispatch(getWeeks());
  }, [leagueList]);


  useEffect(() => {
    if (auth) {
      getLeagueList()
    }
    dispatch(getNotificationCount());
  }, [auth]);

  useEffect(() => {
    setTimeout(() => {
      setSelected(weekIndex)
      setLoading(true)
    }, 100);
  }, [weekIndex])

  useEffect(() => {
    // console.log(LeagueID,'======>>')
    // console.log('======>>')
    if (leagueList && LeagueID !== null) {
      let data = leagueList?.filter((item) => item?.LeagueID === LeagueID)
      // console.log(data);
      setSelectedValue(data[0])
    }
  }, [leagueList])


  const handleMobileBack = () => {
    if (window?.location?.pathname === '/friendChat') {
      dispatch({
        type: IS_CHAT_SCREEN,
        payload: false
      })
    }
    navigate(-1)
    console.log('click on back button')
  }

  const handelSelect = (item) => () => {
    setSelectedValue(item);
    dispatch(getPrizes(item?.LeagueID))
    console.log(item);
    dispatch(setSelectedLeague(item));
    if (window?.location?.pathname === "/") {
      dispatch(setLoading(true));
      dispatch(getWeeks(item));
    }
    dispatch({
      type: GET_MATCH_LIST,
      payload: {},
    });
    handleCloseLeagueModal()
  };

  const handleFriendChat = () => {
    if (chatScreen && chatScreen) {
      dispatch({
        type: IS_CHAT_SCREEN,
        payload: false
      })
    } else {
      navigate('/friendChat')
    }
  }


  return (
    <>
      <div className="mobile_header p-4 d-flex align-items-center">

        {location?.pathname !== '/' ? (isUrl.includes(location?.pathname) ?
          <a className="mobile_nav" onClick={() => handleOpenLeagueModal()}>
            <img src={menuIcon} alt="" />
          </a>
          :
          <a className="mobile_nav" onClick={() => handleMobileBack()} >
            <img src={backArrow} alt="" />
          </a>)
          :<a></a>
        }
        {titleURL.includes(location?.pathname) &&
          <div className="header_center">
            {leagueList?.find((item) => item?.LeagueID === LeagueID)?.LeagueName}
          </div>}
        <div className="message_header">
          <a className="message_link">
            <img src="assets/mobileimages/message.svg" alt="" onClick={() => handleFriendChat()} />
          </a>
        </div>

        <Offcanvas
          show={isOpen}
          onHide={() => handleCloseLeagueModal()}
          placement="bottom"
          className="create_comp my_competitions"
        >
          <div className="scroll_pic">
            {" "}
            <img src="./assets/mobileimages/scroll_pic.png" alt="" />
          </div>

          <Offcanvas.Body className="text-start my_competitions">
            <h3>My Competitions</h3>
            <Form>

              {leagueList && leagueList?.map((item, i) => {
                return (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={item?.LeagueID === LeagueID}
                      onChange={handelSelect(item)}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      {item?.LeagueName}
                    </label>
                  </div>
                )
              })}


              <Button className="accept_btn w-100 d-flex justify-content-center align-items-center">
                <img
                  src="./assets/mobileimages/searchBlack.svg"
                  alt=""
                  className="me-2"
                />
                Find Competitions
              </Button>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

export default MobileHeader;
