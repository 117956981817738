import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostList, setLoading } from '../../redux/actions/DashboardActions'
import Session from "../../utils/session";
import Comments from "./Comments";
import moment from "moment/moment";

import { Accordion, Button, Modal, Tab, Tabs } from "react-bootstrap";

import CreatePost from '../models/CreatePost'
import Friends_chat from '../Friends_chat'
import { GET_POST_LIST, IS_CHAT_OPEN, IS_CHAT_SCREEN } from "../../redux/constant/Dashboard";

import io from "socket.io-client";
import axios from "axios";
const socket = io.connect(process.env.REACT_APP_SOCKET_NODE_URL, { transport: "websocket" });


const FeedSidebar = () => {
  const user = Session.getSession("token");
  const ProfilePic = Session.getSession("ProfilePic");
  const dispatch = useDispatch();
  const sideFeeds = useSelector((state) => state?.dashboard?.sideFeeds);
  const is_chat_open = useSelector((state) => state?.dashboard?.isChatOpen);
  const online_user = useSelector((state) => state?.dashboard?.online_user);
  const [visible, setVisible] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [newsList, setNewsList] = useState(null);



  const handleClick = () => {
    document
      .getElementById("body-parent")
      .classList.add("right-sidebar-toggle");
  };

  const handleclose = () => {
    document
      .getElementById("body-parent")
      .classList.remove("right-sidebar-toggle");
  };


  const handelLike = (data) => () => {
    var socketData = { post_id: data.id, user_id: user?.UserID }
    if (data?.isLiked === '0') {
      socket.emit("post_liked", socketData);
    } else {
      socket.emit("post_unliked", socketData);

    }

  };

  const OpenChatModal = () => {
    dispatch({
      type: IS_CHAT_OPEN,
      payload: !is_chat_open
    })
    dispatch({
      type: IS_CHAT_SCREEN,
      payload: false
    })
    // setreqFriend(!reqFriend)
    setIsChatOpen(!isChatOpen)
  }

  socket.off('postLike').on("postLike", (data) => {
    dispatch(getPostList());
  });
  var val = ''

  const addComment = (item) => (e) => {
    if (e.key == "Enter") {
      if (e?.target?.value !== '') {
        // dispatch(setLoading(true));
        const data = {
          user_id: user?.UserID,
          post_id: item?.id,
          comments: e.target.value,
          parent_comment_id: 0
        }
        socket.emit("commented", data);
        setValueInput('')
      }
    }
  };


  const sendMessageByBtn = (item) => (e) => {
    // dispatch(setLoading(true));
    const data = {
      user_id: user?.UserID,
      post_id: item?.id,
      comments: valueInput,
      parent_comment_id: 0
    }
    socket.emit("commented", data);
    setValueInput('')
  }

  useEffect(() => {
    // dispatch({
    //   type: GET_POST_LIST,
    //   payload: {},
    // });
    socket.on('friend-list', (data) => {
      dispatch(getPostList());
      dispatch(setLoading(false));
    })

    getNewsList()
  }, [])

  const getNewsList = async () => {
    let url = 'https://content.guardianapis.com/search?from-date=2022-12-1&to-date=2023-01-31&section=football&api-key=8ced327d-674a-437f-8d55-18cf288d9a1e&show-fields=all'

    try {

      var config = {
        method: 'get',
        url: url,
      };

      const result = await axios(config);
      if (result?.data?.response?.status === "ok" && result?.statusText === "OK") {
        setNewsList(result?.data?.response?.results);
      }

    } catch (error) {
      console.log(
        "err in new api request==>>>>>",
        error.message
      );
    }
  }


  useEffect(() => {
    dispatch(getPostList());
  }, []);


  useEffect(() => {
    const user = Session.getSession("token");
    if (user?.UserID) socket.emit('getMessageCount', user?.UserID)
  }, []);


  socket.off(`send_message_${user?.UserID}`).on(`send_message_${user?.UserID}`, (data) => {
    socket.emit('getMessageCount', user?.UserID)
  });


  socket.off(`unread_message_count_${user?.UserID}`).on(`unread_message_count_${user?.UserID}`, (data) => {
    setMessageCount(data?.messageCount)
  });



  socket.off(`isOnline_${user?.UserID}`).on(`isOnline_${user?.UserID}`, (data) => {
    console.log('is onlinen calledd==>>>>>', data?.UserID)
    if (data?.UserID == online_user?.UserID) {
      socket.emit(`online_user`, data)
    } else {
      socket.emit('getMessageCount', user?.UserID)
    }
    //  else {
    //   socket.emit(`offline_user`, user?.UserID)
    // }
  });

  const NewsImage = ({ item }) => {
    var el = document.createElement('html');
    el.innerHTML = item?.fields?.main
    let image = el.getElementsByTagName('img');
    return (
      <img src={image[0]?.currentSrc} alt='' />
    )

  }

  const handleReadMoreAboutNew = (item) => {
    window.open(item?.webUrl, '_blank');
  }

  return (
    <>

      {/* <button className="sidebar_toggle" onClick={handleClick}>
        <img src="./assets/images/greearrow.svg" />
      </button> */}

      <div className="feedsidebar">
        <button
          className="cross-icon bg-transparent border-0"
          onClick={handleclose}
        >
          <img src="./assets/images/cross-icon.svg" />
        </button>
        <div className="feed-title d-flex align-items-center justify-content-between mb-4">
          <h3 className="mb-0 text-white">My Feed</h3>
          <a className="theme-btn text-decoration-none" onClick={() => setVisible(true)}>
            Create Post
          </a>
        </div>

        {
          newsList && newsList?.length > 0 && newsList?.map((item, i) => {

            return (
              <div className="post-card" key={i} style={{ marginTop: 20 }} >
                <div className="post-figure mb-3">
                  <figure className="mb-0 lg-circle">
                    <NewsImage item={item} />
                  </figure>
                  <div className="figcaption">
                    <h4>{item?.user_name}</h4>
                    <span> {moment(item?.webPublicationDate).format('DD/MM/yy, hh:mm A')} </span>
                  </div>
                </div>

                <div className="feed-card">
                  <p>
                    {item?.webTitle}
                  </p>

                  {
                    item && <div className="post-image">
                      <a >
                        <NewsImage item={item} />
                      </a>
                    </div>
                  }
                  <Button onClick={() => handleReadMoreAboutNew(item)}>Read More</Button>


                </div>
              </div>
            )
          })
        }


        {
          sideFeeds && sideFeeds.length > 0 && sideFeeds.map((item, i) => (
            <div className="post-card" key={i} style={{ marginTop: 20 }} >
              <div className="post-figure mb-3">
                <figure className="mb-0 lg-circle">
                  <img src={process.env.REACT_APP_IMAGE_URL + item?.user_image} alt="" />
                </figure>
                <div className="figcaption">
                  <h4>{item?.user_name}</h4>
                  <span> {moment(item?.created_at).format('DD/MM/yy, hh:mm A')} </span>
                </div>
              </div>

              <div className="feed-card">
                <p>
                  {item?.description}
                </p>

                {
                  item?.image && <div className="post-image">

                    <a>
                      <img src={item?.type === '2' ? item?.image : `${process.env.REACT_APP_API_URL}/${item?.image}`} alt="" />
                    </a>

                  </div>
                }
                <div className="feed-sec-scoller">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0" className="position-relative">
                      <div className="like-comment">
                        <ul className="list-unstyled comments">
                          <li onClick={handelLike(item)}>
                            {
                              item?.isLiked == 1 ? (
                                <>
                                  <img src="./assets/images/liked-fill.svg" alt="" />
                                  Liked
                                </>
                              ) : (
                                <>
                                  <img src="./assets/images/like-icon.svg" alt="" />
                                  Like
                                </>
                              )
                            }
                            <span className="like_count"> ({item?.likeCount})</span>

                          </li>
                          <li>
                            {" "}
                            <img src="./assets/images/comment.svg" alt="" /> Comment
                            <span className="like_count"> ({item?.postCommentCount})</span>
                          </li>
                        </ul>
                      </div>
                      <Accordion.Header></Accordion.Header>

                      <Accordion.Body className="px-0">

                        <div className="post-editor d-flex align-items-center">
                          <figure className="small-circle">
                            <img src={process.env.REACT_APP_IMAGE_URL + ProfilePic} alt="" />
                          </figure>

                          <div className="send-message-container" style={{ marginLeft: 10, color: 'white' }}>
                            <input
                              type="text"
                              className="form-control w-100 ms-0 transparent px-3 custom-post-comment-input"
                              placeholder="Write a comment..."
                              onKeyDown={addComment(item)}
                              onChange={(e) => setValueInput(e.target.value)}
                              value={valueInput}
                            />


                            <div className="send-message-btn" onClick={sendMessageByBtn(item)} style={{ marginLeft: 5, }} >
                              <i className="fa fa-paper-plane" aria-hidden="true" ></i>
                            </div>
                          </div>


                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Write a comment..."
                            // onChange={handelChange}
                            name='post'
                            onKeyDown={addComment(item)}
                          /> */}
                        </div>

                        <Comments item={item} i={i} />


                      </Accordion.Body>

                    </Accordion.Item>
                  </Accordion>
                </div>

              </div>
            </div>
          ))
        }




      </div>
      <button className="message-btn" style={{ display: 'flex', marginLeft: "auto" }}>
        <div>

        </div>
        {/* chat module icon */}
        {/* <div className="position-relative notification">
          {messageCount !== 0 && <span style={{ marginRight: '20px' }}> {messageCount} </span>}
          <img src="./assets/images/chat-icon.svg" alt="" onClick={() => OpenChatModal()} />
        </div> */}

      </button>

      {/* ******************chat */}
      {is_chat_open && <Friends_chat setMessageCount={setMessageCount} />}

      {/* ****************** */}

      <CreatePost visible={visible} setVisible={setVisible} />


    </>
  );
};

export default React.memo(FeedSidebar);
