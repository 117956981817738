
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from "react-router-dom";

import React from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import FeedSidebar from "../components/sideFeedBar/FeedSidebar";

import Slider from 'react-slick';

import Loader from "react-loader";
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import Socket from '../Socket';
import Session from '../utils/session';
import { io } from 'socket.io-client';


// styles
const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1301,
  width: '100%',
  background: 'gray',
  opacity: 0.8
});



const AuthLayout = () => {

  const { loader } = useSelector((state) => state?.dashboard);

  var options = {
    lines: 13,
    length: 20,
    width: 10,
    radius: 30,
    scale: 1.00,
    corners: 1,
    color: '#06bd53',
    opacity: 0.5,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: true,
    hwaccel: true,
    position: 'absolute'
  };

  const user = Session.getSession('token')



  // const socketLogout = () => {
  //   console.log('logout calldddd')
  //   // Socket.emit(`disconnect`);
  //   Socket.disconnect()
  // }


  return (
    <>
      <Loader loaded={!loader} options={options} className="spinner" />
      <div style={{ opacity: loader && '0.3' }} className="main-wrapper-outer">
        <div className="main-dashboard">
          <Sidebar />
          <div className="main" id="main">
            <Header />

            <Outlet />
          </div>
          <FeedSidebar />
        </div>
      </div>
    </>
  );

};

export default AuthLayout;
