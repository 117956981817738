import { USER_REGISTER_DETAILS } from '../constant/Register';

let initialState = {
    user: {} 
};

const RegisterReducer = function (state = initialState, action) {
    switch (action.type) {
        case USER_REGISTER_DETAILS:
            console.log('login dattaa=>>>>', action?.payload?.ResponseCode === '200')
            return {
                ...state,
                user: action?.payload?.Data,
            };
        default:
            return state;
    }
};

export default RegisterReducer;
