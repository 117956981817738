import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLocation, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import helper from "../utils/helper";
import apiPath from "../utils/apiPath";
import Session from "../utils/session";
import debouce from "lodash.debounce";
import { toast, ToastContainer } from "react-toastify";
import moment from 'moment';
import _ from 'lodash'

import { Accordion, Button, Modal, Tab, Tabs, InputGroup, Form } from "react-bootstrap";

import { IS_CHAT_OPEN, IS_CHAT_SCREEN, ONLINE_USER } from "../redux/constant/Dashboard";
import io from "socket.io-client";
const socket = io.connect(process.env.REACT_APP_SOCKET_NODE_URL, { transport: "websocket" });

const Friends_chat = ({ userData }) => {
    const dispatch = useDispatch()
    const bottomRef = useRef(null);
    const user = Session.getSession("token");

    const is_chat_open = useSelector((state) => state?.dashboard?.isChatOpen);
    const chatScreen = useSelector((state) => state?.dashboard?.isChatScreen);
    const online_user = useSelector((state) => state?.dashboard?.online_user);


    const [chatUser, setChatUser] = useState(online_user);
    const [searchFriendList, setSearchFriendList] = useState([]);
    const [FriendList, setFriendList] = useState([]);
    const [chatList, setChatList] = useState(false);
    // const [chatScreen, setChatScreen] = useState(userData);

    const imageURL = process.env.REACT_APP_IMAGE_URL;

    const getChettingList = async (ToUserID) => {
        try {
            const data = {
                "SessionKey": user?.SessionKey,
                "ToUserID": ToUserID
            }

            let res = await helper.api(apiPath.chattingList, "post", data);
            if (res?.ResponseCode === "200") {
                if (_.isEmpty(res?.Data)) {
                    setChatList([]);
                } else {
                    setChatList(res?.Data);
                }
            } else {
                setChatList([]);
            }
        } catch (error) {
            console.log("eror in frind seach api-->>>", error);
        }
    };

    const getFriendList = async (e) => {
        try {
            const data = {
                "SessionKey": user?.SessionKey,
                "searchKEY": e?.target?.value
            }

            let res = await helper.api(apiPath.friendList, "post", data);
            if (res?.ResponseCode === "200") {
                setFriendList(res?.Data);
            } else {
                setFriendList([]);
            }
        } catch (error) {
            console.log("eror in frind seach api-->>>", error);
        }
    };

    const handleFindFriend = async (e) => {
        try {
            const data = {
                "SessionKey": user?.SessionKey,
                "searchKEY": e.target.value
            }

            let res = await helper.api(apiPath.searchFriendChat, "post", data);
            if (res?.ResponseCode === "200") {
                setSearchFriendList(res?.Data);
            } else {
                setSearchFriendList([]);
            }
        } catch (error) {
            console.log("eror in frind seach api-->>>", error);
        }
    };

    const friendRequestSend = (UserGUID) => async () => {
        try {
            const data = {
                "SessionKey": user?.SessionKey,
                "ToUserGUID": UserGUID
            }
            let res = await helper.api(apiPath.sendFriendRequest, "post", data);
            if (res?.ResponseCode === "200") {
                socket.emit(`notification_send`, { UserGUID });
                toast.success(res.Message);
                dispatch({
                    type: IS_CHAT_OPEN,
                    payload: false
                })
            } else {
                toast.error(res.Message);
            }
        } catch (error) {
            console.log('error in send friend request api=>>>>', error.message)
        }
    }

    const handelChat = (item) => async () => {
        getChettingList(item?.UserID)
        setChatUser(item)
        dispatch({
            type: IS_CHAT_SCREEN,
            payload: true
        })
        dispatch({
            type: ONLINE_USER,
            payload: item
        })
        setTimeout(() => {
            socket.emit('getMessageCount', user?.UserID)
        }, 500);


    }

    const handelBack = async () => {
        // getFriendList()
        dispatch({
            type: IS_CHAT_OPEN,
            payload: !is_chat_open
        })
        dispatch({
            type: IS_CHAT_SCREEN,
            payload: false
        })
        dispatch({
            type: ONLINE_USER,
            payload: null
        })
    }

    const handleCloseChatScreen = () => {
        dispatch({
            type: IS_CHAT_OPEN,
            payload: !is_chat_open
        })
        dispatch({
            type: IS_CHAT_SCREEN,
            payload: false
        })
    }

    const sendMessage = (e) => {
        if (e.key == "Enter") {
            if (e?.target?.value !== '') {
                const data = {
                    UserID: Number(user?.UserID),
                    ToUserID: Number(chatUser?.UserID),
                    Message: e.target.value,
                }
                setChatList([...chatList, data])
                socket.emit("send_message", data);
                // getFriendList(Number(chatUser?.UserID))
                e.target.value = ''
                document.querySelector('.custom-search-input').value = ''
            }
        }
    };

    const sendMessageByBtn = (e) => {
        var target = document.querySelector('.custom-search-input')
        e.key = "Enter";
        e.target.value = target?.value
        sendMessage(e)
    }

    const debouncedResults = useMemo(() => {
        return debouce(handleFindFriend, 300)
    }, [])

    const getFriendListDebounce = useMemo(() => {
        return debouce(getFriendList, 300)
    }, [])


    socket.off(`send_message_${user?.UserID}`).on(`send_message_${user?.UserID}`, (data) => {
        if (data?.message?.UserID == chatUser?.UserID) {
            setChatList([...chatList, data?.message])
        }
        getFriendList()
    });


    socket.off(`isOnline_${user?.UserID}`).on(`isOnline_${user?.UserID}`, (data) => {
        if (data?.UserID != online_user?.UserID) {
            getFriendList()
        }
    });


    useEffect(() => {
        if (chatScreen) {
            getChettingList(chatUser?.UserID)
        } else {
            getFriendList()
        }
    }, [])

    useEffect(() => {
        if (chatScreen) {
            const element = bottomRef?.current
            element?.scroll({
                top: element.scrollHeight + 1000,
            })
        }
    }, [bottomRef?.current, chatList])


    return (

        is_chat_open &&
        <div className="feedsidebar-chat mt-5">
            {
                chatScreen &&
                <>
                    <div className="common-tab chat-tab">
                        <div className="chat">

                            <h3 className="chat-username">
                                <span onClick={handelBack}>
                                    <i className="fas fa-long-arrow-left"></i>
                                </span>
                                <img src={imageURL + chatUser?.ProfilePic} className="user_chat_image" alt="logo" />
                                {chatUser?.FirstName + " " + chatUser?.LastName}
                            </h3>

                            <div className="conversation" >
                                <div id="messages" className="messages" ref={bottomRef} >
                                    {chatList && chatList.length > 0 ? chatList.map((item, i) => (
                                        <div key={i} className={`msg-text ${item?.UserID == user.UserID && 'owner'}`}>
                                            <span className="text">
                                                {item?.Message}
                                                <p style={{ color: '#afafaf', fontSize: 9, marginBottom: 'auto', marginTop: '2px' }}>{moment(item?.EntryDate).format('DD/MM, hh:mm A')}</p>
                                            </span>
                                        </div>
                                    )) : ('no message found')}

                                </div>
                            </div>
                            <div className="form-group common-input chat-search-tab position-relative ">

                                <div className="send-message-container">
                                    <input
                                        type="text"
                                        className="form-control w-100 ms-0 transparent px-3 custom-search-input"
                                        placeholder="Type....."
                                        onKeyDown={sendMessage}
                                    />

                                    <div className="send-message-btn" onClick={sendMessageByBtn} >
                                        <i className="fa fa-paper-plane" aria-hidden="true" ></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <div className="common-tab" >
                        <span className="cross-icon-tab"> <img src='./assets/images/cross-icon.svg' alt='' onClick={() => handleCloseChatScreen()} /> </span>
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className=""
                        >
                            <Tab eventKey="home" title="My friends" className="mb-0">

                                <div className="form-group common-input position-relative">
                                    <span className="search">
                                        <img src="./assets/images/search-icon.svg" alt="" />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control w-100 ms-0 mb-4 transparent"
                                        placeholder="Write your friend's name..."
                                        onChange={getFriendListDebounce}
                                    />
                                </div>


                                <ul className="list-unstyled">
                                    {
                                        FriendList && FriendList.length > 0 ?
                                            FriendList.map((item, i) => (
                                                <li key={i} onClick={handelChat(item)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <a>
                                                        {" "}
                                                        <div className="figure">
                                                            <img src={imageURL + item?.ProfilePic} alt="" />
                                                        </div>
                                                        {" "}
                                                        {item?.FirstName + " " + item?.LastName}
                                                    </a>
                                                    <div className="position-relative notification">
                                                        {item?.unreadCount != 0 && <span style={{ marginRight: '20px' }}> {item?.unreadCount} </span>}
                                                    </div>
                                                </li>
                                            ))
                                            :
                                            (
                                                <span>no data found!</span>
                                            )
                                    }

                                </ul>
                            </Tab>
                            <Tab eventKey="profile" title="Find friends" >

                                <div className="form-group common-input position-relative">
                                    <span className="search">
                                        <img src="./assets/images/search-icon.svg" alt="" />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control w-100 ms-0 mb-4 transparent"
                                        placeholder="Find a friend..."
                                        onChange={debouncedResults}
                                    />


                                </div>

                                <ul className="list-unstyled">
                                    {
                                        searchFriendList && searchFriendList.length > 0 ?
                                            searchFriendList.map((item, i) => {

                                                return (
                                                    <li className="d-flex align-items-center justify-content-between" key={i}>
                                                        <a >
                                                            {" "}
                                                            <div className="figure">
                                                                <img src={imageURL + item?.ProfilePic} alt="" />
                                                            </div>
                                                            {" "}
                                                            {item?.FirstName + " " + item?.LastName}
                                                        </a>
                                                        {item?.isAlreadyFriend === '0' ?
                                                            <Button className="theme-btn" onClick={friendRequestSend(item.UserGUID)}>+ Add</Button>
                                                            :
                                                            <Button className="theme-btn" >Friend</Button>
                                                        }
                                                    </li>
                                                )
                                            })
                                            : (<span>no data found!</span>
                                            )
                                    }

                                </ul>

                            </Tab>
                        </Tabs>
                    </div> */}
                </>

            }






        </div>
    )
}

export default Friends_chat